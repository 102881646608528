import { Badge, Tag } from "antd"
import first from "../../styles/images/gold-cup.png"
import second from "../../styles/images/silver-cup.png"
import third from "../../styles/images/bronze-cup.png"
export const AdminTag=({level})=>{
    if(level===0){
        return <Tag color="blue" >{"Superuser".toUpperCase()}</Tag>
    }
    else if(level>1){
        return <Tag color="green" >{"Admin".toUpperCase()}</Tag>
    }else{
        return <Tag color="red" >{"Employee".toUpperCase()}</Tag>
    }
}
AdminTag.Action=(props)=>{
    const {abbr,action}=props
    const sentence=abbr.toUpperCase()
    switch(action){
        case "ATC":
            return <Tag color="volcano" {...props}>{sentence}</Tag>
        case "AEE":
            return <Tag color="green" {...props}>{sentence}</Tag>
        case "CEP":
            return <Tag color="red" {...props}>{sentence}</Tag>
        default:
            return <Tag color="blue" {...props}>{sentence}</Tag>
    }
}

export const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <AdminTag.Action
        abbr={label}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
        action={label}
      >
      </AdminTag.Action>
    );
  };
   AdminTag.Presence = (props) => {
    const {presence}=props
    switch(presence){
        case 1:
            return <Badge color="red" />
        default:
            return <Badge color="green" />

    }
  }

  AdminTag.DetectionStatus = (props) => {
    const {status}=props
    switch(status){
        case 1:
            return <Badge status="success" />
        default:
            return <Badge status="error" />

    }
  }

  AdminTag.Cup = (props) => {
    const {pos}=props
    switch(pos){
        case 1:
            return <img src={first} width={25}/>
        case 2:
            return <img src={second} width={25}/>
        case 3:
            return <img src={third} width={25}/>
        default:
            return <></>
    }
  }