import { Col, Row } from "react-bootstrap"
import CustomInput from "../CustomInput"
import { uploadFile } from "../../utils"

export const PersonalInfo = (props) => {
    const { formik } = props
    const handleIMage=async (event)=>{
        const image=await uploadFile(event.target.files[0])
        formik.setFieldValue("person.image", image)
    }
    return (
        <>
            <h3>Personal Information</h3>
            <Row>
                <Col lg="6" md="6" sm="12">
                    <CustomInput
                        type="text"
                        name="person.surname"
                        label="Surname"
                        onChng={formik.handleChange("person.surname")}
                        onBlr={formik.handleBlur("person.surname")}
                        val={formik.values.person.surname}
                    />
                    <div className="error">
                        {formik.touched.person?.surname && formik.errors.person?.surname}
                    </div>
                </Col>
                <Col lg="6" md="6" sm="12">
                    <CustomInput
                        type="text"
                        name="person.firstname"
                        label="First name"
                        onChng={formik.handleChange("person.firstname")}
                        onBlr={formik.handleBlur("person.firstname")}
                        val={formik.values.person.firstname}
                    />
                    <div className="error">
                        {formik.touched.person?.firstname && formik.errors.person?.firstname}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="text"
                        name="person.idnumber"
                        label="ID number"
                        onChng={formik.handleChange("person.idnumber")}
                        onBlr={formik.handleBlur("person.idnumber")}
                        val={formik.values.person.idnumber}
                    ></CustomInput>
                    <div className="error">
                        {formik.touched.person?.idnumber && formik.errors.person?.idnumber}
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="date"
                        name="person.birthday"
                        label="birthday"
                        onChng={formik.handleChange("person.birthday")}
                        onBlr={formik.handleBlur("person.birthday")}
                        val={formik.values.person.birthday}
                    />
                    <div className="error">
                        {formik.touched.person?.birthday && formik.errors.person?.birthday}
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="text"
                        name="person.address"
                        label="Address"
                        onChng={formik.handleChange("person.address")}
                        onBlr={formik.handleBlur("person.address")}
                        val={formik.values.person.address}
                    />
                    <div className="error">
                        {formik.touched.address && formik.errors.person?.address}
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="file"
                        name="person.image"
                        label="Your image here"
                        onChng={handleIMage}
                        onBlr={formik.handleBlur("person.image")}
                    />
                    <img className="img w-25" src={formik.values.person.image} alt="Selected Image" />
                    <div className="error">
                        {formik.touched.image && formik.errors.person?.image}
                    </div>
                </Col>

            </Row>

        </>
    )
}