import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./pages/Login";
import MainLayout from './components/MainLayout';
import { AddWorkStation } from './pages/AddWorkStation';
import { AddRoom } from './pages/AddRoom';
import { AddCamera } from './pages/AddCamera';
import { AddEmploye } from './pages/AddEmploye';
import {  ListRoom } from './pages/ListRoom';
import { Register } from './pages/Register';
import { NotvalidateAdmin} from './pages/NotValidate';
import { ListAdministrator } from './pages/ListAdministrator';
import { RoomDetails } from './pages/RoomDetails';
import { ListPresence } from './pages/ListPresence';
import { ListCamera } from './pages/ListCamera';
import { AddDetection } from './pages/AddDetection';
import { ListMissing } from './pages/ListMissing';
import { DashBoard } from './pages/DashBoard';
import { ListDetection } from './pages/ListDetection';
import { ListEmployee } from './pages/ListEmployee';
import { EmployeeDetails } from './pages/EmployeeDetails';
import { ListOvertime } from './pages/ListOvertime';
function App() {
  return (
      <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/watch" element={<MainLayout></MainLayout>}>
          <Route path="workstation" element={<AddWorkStation/>}></Route>
          <Route index element={<DashBoard/>}></Route>
          <Route path="room" element={<AddRoom/>}></Route>
          <Route path='room/list' element={<ListRoom/>}></Route>
          <Route path='room/list/:id/:key/:name' element={<RoomDetails/>}></Route>
          <Route path="cam" element={<AddCamera/>}></Route>
          <Route path="cam/list" element={<ListCamera/>}></Route>
          <Route path="employee" element={<AddEmploye/>}></Route>
          <Route path="validation" element={<NotvalidateAdmin/>}></Route>
          <Route path="attendance/list" element={<ListPresence/>}></Route>
          <Route path="detection/history" element={<ListDetection/>}></Route>
          <Route path="attendance" element={<AddDetection/>}></Route>
          <Route path="delays/list" element={<ListMissing/>}></Route>
          <Route path="employee/list" element={<ListEmployee/>}></Route>
          <Route path="employee/list/overtime" element={<ListOvertime/>}></Route>
          <Route path="employee/list/:id/:name" element={<EmployeeDetails/>}></Route>
          <Route path="admin">
            <Route index element={<ListAdministrator/>}></Route>
          </Route>
        </Route>
      </Routes>
    </Router>

  );
}

export default App;
