import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getHistory, saveManually } from "../features/presence/presenceSlice"
import moment from "moment"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { getWorkingEmployees } from "../features/employe/employeSlice"
import { Col, Row } from "react-bootstrap"
import { Button, Table } from "antd"
import CustomInput from "../components/CustomInput"

export const AddDetection = () => {
    const dispatch = useDispatch()
    const presence_state = useSelector((state) => state.presence)
    const employee_state = useSelector((state) => state.employee)
    const [searcheddate, setSearched] = useState(moment().format('YYYY-MM-DD'))
    const [time, setTime] = useState(undefined)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const { histories, isLoading, isError } = presence_state
    const { employees } = employee_state
    useEffect(() => {
        dispatch(getHistory(moment().format('YYYY-MM-DD')))
        dispatch(getWorkingEmployees())
    }, [])

    console.log(histories)

    const history_columns = [
        {
            title: "Complete Name",
            dataIndex: "complete_name",
            key: "complete_name"
        },
        {
            title: "Email Address",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Workstation",
            dataIndex: "jobname",
            key: "jobname"
        }
    ]

    const data = employees.map((emp, index) => {
        return {
            key: emp.id,
            complete_name: `${emp.firstname} ${emp.surname}`,
            email: emp.email,
            jobname: emp.jobname,

        }
    })

    const gotherData = () => {
        const retour = []
        for (let i = 0; i < selectedRowKeys.length; i++) {
            retour.push({
                employee: selectedRowKeys[i],
                established_at: searcheddate,
                detected_at: time
            })

        }
        dispatch(saveManually(retour))
    }
    // loadena list employe, selectoin les employe, sauvre, liste histoique
    const hasSelected = selectedRowKeys.length > 0;
    return (
        <MainWaitComponent isLoading={isLoading || presence_state.isLoading}>
            <MainWaitComponent.ErrorPage status={presence_state.errorstatus} error={isError || presence_state.isError}>
                <Row className="mb-3">
                    <Col lg="4" md="4" sm="4">
                        <CustomInput label="Date" onChng={(e) => setSearched(moment(e.target.value).format('YYYY-MM-DD'))} type="date" />
                    </Col>
                    <Col lg="4" md="4" sm="4"><CustomInput onChng={(e) => { setTime(e.target.value) }} label="Checked Time" type="time" /></Col>
                </Row>
                <Row>
                    <Button onClick={() => gotherData()} type="primary" style={{ width: "150px", marginLeft: "20px", marginBottom: "7px" }}>Manually Checked</Button>
                </Row>
                <Row>
                    <span
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        {hasSelected ? `Selected ${selectedRowKeys.length} item${selectedRowKeys.length > 1 ? 's' : ""}` : ''}
                    </span>
                    <Table rowSelection={rowSelection} columns={history_columns} dataSource={data} pagination={{ pageSize: 10, }} />
                </Row>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}