import React from 'react';
import ReduxFormSave from '../components/handler/save/ReduxFormSave';
import formService from '../features/form/formServices';
import { createworkstation } from '../features/workstation/workstationSlice';
export const AddWorkStation = ()=>{

    return (
        <React.Fragment>
            <section>
            <h1>Add a new workstation </h1>
        </section>
            <hr/>
        <section>
            <ReduxFormSave reducerkey="workstation" savemethod={createworkstation} loadfield={async()=>{return await formService.loadsave("api/workstation")}}></ReduxFormSave>
        </section>
        
        </React.Fragment>
    )
}