import React from 'react';
import { Button, Result } from 'antd';

export const ErrorPage = ({status,subTitle,action}) => 
  {

    let message="Sorry an error occurred"
    let st="error"
    if(status===401){
      message="You are not allowed to access this page"
    }
    if(status===500){
      message="The server returned an error"
      st="500"
    }
  return (<Result
    status={st}
    title={status}
    subTitle={message}
    extra={<Button type="primary" onClick={()=>action()}>Back Home</Button>}
  />)
}

