import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authServices";

const getUserfromLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

/**
* Initial state for the application.
* - `user`: State retrieved from localStorage.
* - `notvalidate`: Array for not validated administrators.
* - `validate`: Array for validated administrators.
* - `actions`: Array for actions.
* - `isError`: Flag indicating if an error has occurred.
* - `isLoading`: Flag indicating if the application is in a loading state.
* - `isSuccess`: Flag indicating if the last action was successful.
* - `message`: A message associated with the state.
*/
const initialState = {
  user: getUserfromLocalStorage, //state from localStorage
  notvalidate: [],
  validate: [],
  actions: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

/**
 * Asynchronous thunk for user login.
 *
 * @param {Object} userData - User credentials for login.
 * @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
 * @returns {Promise<Object>} - Returns a Promise resolving to the result of the login operation.
 */

export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      return await authService.login(userData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Asynchronous thunk for user logout.
 *
 * @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
 * @returns {Promise<Object>} - Returns a Promise resolving to the result of the logout operation.
 */
export const logout = createAsyncThunk(
  "auth/logout",
  async (thunkAPI) => {
    try {
      return await authService.logout()
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

/**
 * Asynchronous thunk for user registration.
 *
 * @param {Object} userData - User data for registration.
 * @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
 * @returns {Promise<Object>} - Returns a Promise resolving to the result of the registration operation.
 */
export const register = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      return await authService.register(userData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })


/**
 * Asynchronous thunk for retrieving not validated administrators.
 *
 * @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
 * @returns {Promise<Object>} - Returns a Promise resolving to the result of the retrieval operation.
 */
export const getnotvalidateadmin = createAsyncThunk(
  "auth/getnotvalidateadmin",
  async (thunkAPI) => {
    try {
      return await authService.getnotvalidateadmin();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

/**
* Asynchronous thunk for validating an administrator.
*
* @param {Object} data - Data object containing the administrator's ID and level for validation.
* @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
* @returns {Promise<Object>} - Returns a Promise resolving to the result of the validation operation.
*/
export const validateadmin = createAsyncThunk(
  "auth/validateadmin",
  async (data, thunkAPI) => {
    try {

      // Call the authService to perform the validation operation.
      return await authService.validate(data.id, { level: data.level })
    } catch (error) {
      // Reject with the error value if an error occurs during validation.
      return thunkAPI.rejectWithValue(error)
    }
  })

/**
* Asynchronous thunk for retrieving a list of validated administrators.
*
* @param {Object} data - Optional data object for additional parameters in the retrieval operation.
* @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
* @returns {Promise<Object>} - Returns a Promise resolving to the result of the retrieval operation.
*/
export const validatesList = createAsyncThunk(
  "auth/validatesList",
  async (data = undefined, thunkAPI) => {
    try {
      return await authService.validatesList(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

/**
 * Asynchronous thunk for loading actions into the administrator database.
 *
 * @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
 * @returns {Promise<Object>} - Returns a Promise resolving to the result of the load operation.
 */
export const loadDatabaseAction = createAsyncThunk(
  "auth/loadDatabaseAction",
  async (thunkAPI) => {
    try {

      // Call the authService to perform the load operation.
      return await authService.loadDatabaseAction();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

  /**
 * Asynchronous thunk for adding or updating access for an administrator.
 *
 * @param {Object} data - Data object containing the administrator's ID, access data, and an optional action flag.
 * @param {Object} thunkAPI - Redux Toolkit's `thunkAPI` object.
 * @returns {Promise<Object>} - Returns a Promise resolving to the result of the add/update access operation.
 */
export const addOrUpdateAccess = createAsyncThunk(
  "auth/addOrUpdateAccess",
  async (data, thunkAPI) => {
    try {
      return await authService.addOrUpdateAccess(data.id, data.access, data.action);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })


export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (buildeer) => {
    buildeer
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = "success";
      })
      .addCase(login.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.createduser = action.payload;
        state.message = "Your request has been processed.";

      })
      .addCase(register.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getnotvalidateadmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getnotvalidateadmin.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.notvalidate = action.payload;
        state.message = "Your request has been processed.";

      })
      .addCase(getnotvalidateadmin.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = "disconnect";
      })
      .addCase(logout.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;

      })
      .addCase(validateadmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateadmin.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.createdUser = action.payload;
        state.message = "Sucessfully";
      })
      .addCase(validateadmin.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(validatesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validatesList.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.validate = action.payload;
        state.message = "Sucessfully";
      })
      .addCase(validatesList.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(loadDatabaseAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadDatabaseAction.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.actions = action.payload.action;
        state.message = "Sucessfully";
      })
      .addCase(loadDatabaseAction.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(addOrUpdateAccess.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrUpdateAccess.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedaccess = action.payload;
        state.message = "Sucessfully";
      })
      .addCase(addOrUpdateAccess.rejected, (state, action) => {
        state.errorstatus = state.errorstatus = action?.payload ? action.payload.response?.status : 401;;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })


  },
});
export default authSlice.reducer;