import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import ScInput from '../inputs/ScComponent'
import { Button, Row } from 'react-bootstrap'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ReduxFormUpdate=(props)=>{
    const dispatch = useDispatch()
    const [fields, setFields] = useState([]);
    const [error, setError] = useState(null);
    const {updatemethod,loadfield,dataid,data,reducerkey }=props
    const ilaina = []

  const change = (za) => {
    if (za.fields.type === null) {
      za.fields.type = undefined
    }
    if (za.fields.list === null) {
      za.fields.list = undefined
    }
  }
  useEffect(() => {
    
    let get = async () => {
      try{
        let response=await loadfield()
      let temp = response.data;
    //   console.log(temp)
      for (let i of temp) {
              change(i);
            }
            setFields(temp);
      }catch(error){
        setError(error)
        // if (error.response?.status){
        //   return <ErrorPage status={error.response?.status}></ErrorPage>
        // }
        // window.location.href="/"
      }
    };
    get();
  }, []);

  function padZero(value) {
    return value.toString().padStart(2, '0');
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const alefa = new FormData(event.currentTarget)

    let brand = Object.fromEntries(alefa.entries())
    for (let ina = 0; ina < ilaina.length; ina++) {
      if (ilaina[ina] !== '') {
        let value = brand[ilaina[ina]]
        // console.log(i)
        if (typeof value === 'string') {
          const dateValue = new Date(value)
          const formattedDate = `${dateValue.getFullYear()}-${padZero(dateValue.getMonth() + 1)}-${padZero(dateValue.getDate())}T${padZero(dateValue.getHours())}:${padZero(dateValue.getMinutes())}:${padZero(dateValue.getSeconds())}+00:00`;
          brand[ilaina[ina]] = formattedDate
        }
      }
    }
    await Promise.all(
      fields.map(async (field) => {
        const { fieldname } = field.fields;
        const fieldValue = alefa.get(fieldname);
        console.log("field " + fieldname);

        if (fieldValue) {
          // Check if the field is a file input
          if (field.fields.type === 'file') {
            const file = fieldValue ;
            const base64 = await convertFileToBase64(file);
            // console.log("ato " + alefa.get(fieldname) + " " + field.fields.type + base64);
            brand[fieldname] = "data:image//jpeg;base64," + base64;
          } else {
            brand[fieldname] = fieldValue;
          }
        }
      })
    );
    await dispatch(updatemethod({id:dataid,brand}))
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          const base64 = reader.result.toString().split(',')[1];
          resolve(base64);
        } else {
          reject('Error converting file to Base64');
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const defv = (dt) => {
    // let temp=
    if (dt.fields.type === 'datetime-local') {
      ilaina.push(dt.fields.fieldname)
      return new Date(data[dt.fields.fieldname]).toISOString().slice(0, 16)
    }
    else if (dt.fields.type === 'date') {
      console.log('daty: ' + data[dt.fields.fieldname])
      return data[dt.fields.fieldname].replace('/', '-')
    }
    else {
      return data[dt.fields.fieldname]
    }
  }

  const newobject = useSelector((state) => state[reducerkey]);
  const {
    isSuccess,
    isError,
    isLoading,
  } = newobject;

  useEffect(() => {
    console.log(newobject);
    if (isSuccess && newobject[`created${reducerkey}`]){
      toast.success("Added Successfullly!");
    }
    // if (isSuccess && updatedBoutique) {
    //   toast.success("Category Updated Successfullly!");
    //   navigate("/admin/list-category");
    // }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading, newobject, reducerkey]);
  return (
    <>
    <ToastContainer />
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        {fields.map((f, index) => (
          <Row key={index}>
            <ScInput {...f} moreprops={{ defaultValue: defv(f) }} />
          </Row>
        ))}
        <Row className='mt-1'>
          <Button variant="primary" className="mt-3" type="submit">
            Update
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default ReduxFormUpdate