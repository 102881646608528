import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMissing } from "../features/presence/presenceSlice"
import moment from "moment"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Container, Row } from "react-bootstrap"
import { Button, Input, Space, Table } from "antd"
import { AiOutlineFileSearch } from "react-icons/ai";
import { toast } from "react-toastify"
export const ListMissing = () => {
    const dispatch = useDispatch()
    const [searcheddate, setSearched] = useState(moment().format('YYYY-MM-DD'))
    const state = useSelector((state) => state.presence)
    const { missings, isLoading, isError } = state
    useEffect(() => {
        dispatch(getMissing(moment().format('YYYY-MM-DD')))
    }, [])

    const columns = [
        {
            title: "Date",
            dataIndex: "established_at",
            key: "established_at"
        },
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename",
        },
        {
            title: "Job",
            dataIndex: "jobname",
            key: "jobname",
        },
    ]

    const handleDateChange = (e) => {
        // Get the selected date from the input
        const selectedDate = e.target.valueAsDate;

        // Format the date using moment
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');

        // Set the formatted date in the state
        setSearched(formattedDate);
    };

    // Transforming data and options
    const data = missings.map((item) => {
        return {
            completename: item.completename,
            jobname: item.jobname,
            established_at: item.established_at
        };
    });

    const onSearch = async () => {
        dispatch(getMissing(searcheddate))
    }

    useEffect(() => {
        if (isError) {
            toast.error("An error occured! Please refresh the page")
        }
    }, [isError])
    return (
        <MainWaitComponent isLoading={isLoading}>
            <MainWaitComponent.ErrorPage error={isError} status={state.errorstatus}>
                <Container>
                    <Row>
                        <h1>Missings lists</h1>
                    </Row>
                    <hr />
                    <Space direction="vertical" size="middle">
                        <Space.Compact style={{ width: '100%' }}>
                            <Input type="date" onChange={handleDateChange} defaultValue={searcheddate} />
                            <Button onClick={onSearch} type="primary"><AiOutlineFileSearch /></Button>
                        </Space.Compact>
                    </Space>
                    <Row>
                        <Table columns={columns} dataSource={data} pagination={{ pageSize: 10, }} />
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )

}