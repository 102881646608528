import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getCam } from "../features/cam/camSlice"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Accordion, Col, Row } from "react-bootstrap"
import { Button, Select, Table } from "antd"
import { getWorkingEmployees } from "../features/employe/employeSlice"
import CustomInput from "../components/CustomInput"
import moment from "moment"
import { addRestriction, getRoomRestriction } from "../features/room/roomSlice"

export const RoomDetails = () => {
    const { id } = useParams()

    const dispatch = useDispatch()
    const cameraState = useSelector((state) => state.cam)
    const employeeState = useSelector((state) => state.employee)
    const roomState = useSelector((state) => state.room)

    const { isLoading, isError, cams } = cameraState
    const { employees } = employeeState
    const { restrictions, addedrestriction } = roomState
    // employee list
    const [selected, setSelected] = useState([]);
    const [apply, setApply] = useState(moment().format("YYYY-MM-DD"))


    useEffect(() => {
        if (id) {
            dispatch(getCam({ roomid: id }))
            dispatch(getWorkingEmployees())
            dispatch(getRoomRestriction(id))
        }
    }, [id])

    useEffect(() => {
        if (addRestriction) {
            dispatch(getRoomRestriction(id))
        }
    }, [id, addedrestriction])
    const cameracolumn = [
        {
            title: "N°",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Camera name",
            dataIndex: "cameraname",
            key: "cameraname"
        },
        {
            title: "Access Tool",
            dataIndex: "accesstool",
            key: "accesstool"
        },

    ]

    const restrictioncolumn = [
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename"
        },
        {
            title: "Job Name",
            dataIndex: "jobname",
            key: "jobname"
        },
        {
            title: "Applied at",
            dataIndex: "apply_at",
            key: "apply_at"
        }
    ]

    const cameradata = cams
    const restrictiondata = restrictions
    const options = employees.map((item) => {
        return {
            label: item.firstname,
            value: item.id,
        };
    });

    const handleChange = (value) => {
        setSelected(value)
    };

    const addRestrictions = () => {
        let retour = []
        for (let i = 0; i < selected.length; i++) {
            retour.push({
                employee: selected[i],
                apply_at: apply
            })
        }
        dispatch(addRestriction({ id, list: retour }))
    }
    return (
        <MainWaitComponent isLoading={isLoading || employeeState?.isLoading || roomState.isLoading}>
            <Row>
                <h1>Room Details</h1>
            </Row>
            <hr />
            <Row className="mb-3">
                <Col lg="12" md="12" sm="12">
                    <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Add new Restriction</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col lg="12" md="12" sm="12">
                                        <CustomInput
                                            type="date"
                                            label="Date"
                                            onChng={(e) => setApply(moment(e.target.valueAsDate).format("YYYY-MM-DD"))}
                                        />
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <label className="mb-2">Working Employees</label>
                                        <Select
                                            size="large"
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            placeholder="List of Employees..."
                                            onChange={handleChange}
                                            options={options}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Button type="primary" onClick={addRestrictions}>Add</Button>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                </Col>
            </Row>
            <Row>
                <Col lg="6" md="6" sm="12">
                    <h3 className="text-center">Camera List</h3>
                    <Table columns={cameracolumn} dataSource={cameradata} scroll={{
                                    y: 110,
                                }}></Table>
                </Col>
                <Col lg="6" md="6" sm="12">
                <h3 className="text-center">Restriction List</h3>
                    <Table columns={restrictioncolumn} dataSource={restrictiondata} scroll={{
                                    y: 110,
                                }} ></Table>
                </Col>
            </Row>
        </MainWaitComponent>
    )
}