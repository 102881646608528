import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOrUpdateAccess, loadDatabaseAction, validatesList } from "../features/auth/authSlice";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MainWaitComponent } from "../components/wait/MainWaitComponent";
import { AdminTag, tagRender } from "../components/admin/AdminTag";
import { Select, Table } from "antd";
import { Input } from 'antd';
import { Helmet } from "react-helmet-async";
const { Search } = Input;
export const ListAdministrator = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  const { validate, actions, isLoading, updatedaccess } = state;

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(validatesList());
    dispatch(loadDatabaseAction());
  }, []);

  useEffect(() => {
    if (updatedaccess) {
      dispatch(validatesList());
    }
  }, [updatedaccess]);

  const addAccess = async (id,action=undefined) => {
    await dispatch(addOrUpdateAccess({ id, access: selected ,action: action}));
  };

  const handleChange = (value) => {
    setSelected(value)
  };
  // Transforming data and options
  const data = validate.map((item) => {
    return {
      id: item.id,
      key: item.id,
      name: item.name,
      email: item.email,
      level: item.level,
      actions: item.actions,
    };
  });

  const options = actions.map((item) => {
    return {
      label: item.abbr,
      value: item.id,
    };
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "level",
      key: "level",
      render: (_, { level }) => {
        return <AdminTag level={level} />;
      },
    },
    {
      title: "Access",
      dataIndex: "actions",
      key: "actions",
      render: (_, { actions }) => {
        return (
          <>
            {actions?.map((action, index) => (
              <AdminTag.Action key={index} abbr={action.abbr} action={action.abbr} />
            ))}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_, { id }) => {
        return (
          <Row>
            <Col>

              <Button variant="primary" onClick={() => addAccess(id)}>
                + Access
              </Button>
            </Col>
            <Col>

              <Button variant="danger" onClick={()=> addAccess(id,true) }>- Access</Button>
            </Col>
          </Row>

        );
      },
    },
  ];

  const onSearch = async (value, _e, info) => {
    // console.log(info?.source, value);
    await dispatch(validatesList(value));
  }

  return (
    <>
      <MainWaitComponent isLoading={isLoading}>
        <MainWaitComponent.ErrorPage error={state.isError} status={state.errorstatus}>
          <Container>
            <Helmet>
              <title>Manage Guard Watch'User</title>
            </Helmet>
            <Row>
              <h1>List of Administrator</h1>
            </Row>
            <hr />
            <Row>
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <p>Search</p>
                  </Col>
                  <Col lg="12" md="12" sm="12">
                    <Search placeholder="input search text" onSearch={onSearch} enterButton />
                  </Col>
                </Row>
              </Col>
              <Col lg="6" md="6" sm="12">
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <p>Available action</p>
                  </Col>
                  <Col lg="12" md="12" sm="12">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      onChange={handleChange}
                      tagRender={tagRender}
                      options={options}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Table columns={columns} dataSource={data} pagination={{ pageSize: 10, }} scroll={{
                y: 900,
              }} />
            </Row>
          </Container>
        </MainWaitComponent.ErrorPage>
      </MainWaitComponent>
    </>
  );
};
