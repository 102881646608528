import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import presenceService from "./presenceServices";

export const getPresence= createAsyncThunk(
    "presence/get",
    async (data, thunkAPI) => {
        try{
            return await presenceService.getPresence(data)
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getHistory=createAsyncThunk(
  "presence/hidtory",
  async (date,thunkAPI)=>{
    try{
      return await presenceService.getHistory(date)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const saveManually=createAsyncThunk(
  "presence/manual",
  async (data,thunkAPI)=>{
    try{
      return await presenceService.saveManually(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getMissing = createAsyncThunk(
  "presence/missing",
  async (date, thunkAPI) => {
    try{
        return await presenceService.getMissingDays(date)
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
}
)

export const getHistoryEmployees = createAsyncThunk(
  "presence/history-emplyee",
  async (data, thunkAPI) => {
    try{
      return await presenceService.getHistoryEmployees(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getDectectionPerHour = createAsyncThunk(
  "presence/dectection-per-hour",
  async (data, thunkAPI) => {
    try{
        return await presenceService.getDectectionPerHour(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getUnreadViolation = createAsyncThunk(
  "presence/violation",
  async (data,thunkAPI)=>{
    try{
      return await presenceService.getUnreadViolation(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)
export const getTop = createAsyncThunk(
  "presence/top",
  async (data, thunkAPI) =>{
    try{
      return await presenceService.getTop(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)
export const resetState = createAction("Reset_all");


const initialState = {
    presences: [],
    missings:[],
    histories:[],
    tops:[],
    violations:undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
  };
  
  export const presenceSlice=createSlice({
    name:"presence",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
      .addCase(getPresence.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPresence.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.presences = action.payload;
      })
      .addCase(getPresence.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getMissing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMissing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.missings = action.payload;
      })
      .addCase(getMissing.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.histories = action.payload;
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getHistoryEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistoryEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.histories = action.payload;
      })
      .addCase(getHistoryEmployees.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getDectectionPerHour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDectectionPerHour.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.perhour = action.payload;
      })
      .addCase(getDectectionPerHour.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        
      })

      .addCase(getUnreadViolation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnreadViolation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.violations = action.payload;
      })
      .addCase(getUnreadViolation.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        
      })
      .addCase(getTop.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTop.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.tops = action.payload;
      })
      .addCase(getTop.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(saveManually.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveManually.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.saved = action.payload;
      })
      .addCase(saveManually.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
    }
})


export default presenceSlice.reducer;