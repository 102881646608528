import { Card, Statistic } from "antd"

export const StatisticBoard = (props)=>{
    return (
        <Card bordered={false}>
        <Statistic
          {...props}
          precision={2}
        />
      </Card>
    )
}

StatisticBoard.Presence = (props)=>{
    return (
        <StatisticBoard title="Presence" value={props.value} valueStyle={{ color: '#1a9609' }}/>
    )
}
StatisticBoard.Custom = (props)=>{
    return (
        <StatisticBoard title={props.title} value={props.value} valueStyle={props.style}/>
    )
}

StatisticBoard.Missing = (props)=>{
    return (
        <StatisticBoard title="Missing" value={props.value} valueStyle={{color:'#ff0000'}}></StatisticBoard>
    )
}
StatisticBoard.Delay = (props)=>{
    return (
        <StatisticBoard title="Delay" value={props.value} valueStyle={{color:'#ff8c00'}}></StatisticBoard>
    )
}