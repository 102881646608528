import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import camService from "./camServices";

export const createcam= createAsyncThunk(
    "cams/create-cam",
    async (cam, thunkAPI) => {
        try{
            return await camService.createCam(cam)
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const updateCam = createAsyncThunk(
  "cams/update-cam",
  async (payload, thunkAPI) => {
    const { id, brand } = payload;
    try {
      return await camService.updateCam(id, brand);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const getCam = createAsyncThunk(
  "cams/get-cam",
  async (data, thunkAPI) => {
    try{
      return await camService.getCameraList(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const resetState = createAction("Reset_all");


const initialState = {
    cams: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
  };
  
  export const camSlice=createSlice({
    name:"cams",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
      .addCase(createcam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createcam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdcam = action.payload;
      })
      .addCase(createcam.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getCam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.cams = action.payload;
      })
      .addCase(getCam.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateCam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedcamera = action.payload;
      })
      .addCase(updateCam.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
    }
})


export default camSlice.reducer;