import axios from "axios";
import { conf, config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
import { handleAccessError, objectToQueryString } from "../../utils";
import { djangoLookups, lookUpsWithValue } from "../../utils/lookups";

/**
 * Asynchronous function to perform user login.
 *
 * @param {Object} user - User credentials object containing necessary login information.
 * @returns {Promise<Object|null>} - Returns a Promise resolving to the response data if successful, otherwise null.
 */
const login = async (user) => {

   // Sending a POST request to the authentication endpoint with the provided user credentials.
    const response = await axios.post(`${base_url}authentifiate/`, user);

    // If the response contains data, store the user information in the local storage.
    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    // Return the response data.
    return response.data;
  };

  /**
 * Asynchronous function to perform user logout.
 */

  const logout=async ()=>{
    // Remove the "user" key from the local storage to log out the user.
    localStorage.removeItem("user");
  }

/**
 * Asynchronous function to register a new administrator.
 *
 * @param {Object} user - User information for the new administrator.
 * @returns {Promise<Object|null>} - Returns a Promise resolving to the response data if successful, otherwise null.
 */
  const newadministrator = async (user)=>{
    return handleAccessError(async () => {
      
      // Sending a POST request to the administrator registration endpoint with the provided user information.
      const response  = await axios.post(`${base_url}guard/api/admin/register/`, user);

      // Return the response data.
      return response.data;
  })
  };

/**
 * Asynchronously retrieves a list of not validated administrators.
 *
 * @returns {Promise<Array|null>} - Returns a Promise resolving to an array of not validated administrators if successful, otherwise null.
 */
const getnotvalidateadmin=async ()=>{
    return handleAccessError(async () => {
      const response  = await axios.get(`${base_url}guard/api/notvalidateadministrator/all/`,conf("SUP"));
      return response.data;
  })
}

/**
 * Asynchronously validates an administrator with the specified ID and data.
 *
 * @param {string} id - The ID of the administrator to be validated.
 * @param {Object} admin - Administrator data for validation.
 * @returns {Promise<Object|null>} - Returns a Promise resolving to the response data if successful, otherwise null.
 */

const validate = async (id,admin)=>{
  return handleAccessError(async () => {
    const response  = await axios.put(`${base_url}guard/api/admin/${id}/`,admin,config);
    return response.data;
  })
}


/**
 * Asynchronously retrieves a list of administrators based on optional search criteria.
 *
 * @param {string} search - Optional search string for filtering administrators.
 * @returns {Promise<Array|null>} - Returns a Promise resolving to an array of administrators if successful, otherwise null.
 */
const validatesList = async (search=undefined)=>{
  const keys={}
  if (search!=undefined){
    lookUpsWithValue(keys,djangoLookups.icontains,"name",search)
    lookUpsWithValue(keys,djangoLookups.icontains,"email",search)

  }
  return handleAccessError(async () => {
    const response  = await axios.post(`${base_url}guard/api/admins/alls/?${objectToQueryString(keys)}`,null,conf("SUP"));
    return response.data;
  })
}


/**
 * Asynchronously performs a load operation on the administrator database.
 *
 * @returns {Promise<Object|null>} - Returns a Promise resolving to the response data if successful, otherwise null.
 */
const loadDatabaseAction= async()=>{
  return handleAccessError(async () => {
    const response  = await axios.post(`${base_url}guard/api/admins/load/`,null,conf("SUP"));
    return response.data;
  })
}

/**
 * Asynchronously adds or updates access for an administrator with the specified ID.
 *
 * @param {string} id - The ID of the administrator for whom access is being added or updated.
 * @param {Object} access - Access data to be added or updated.
 * @param {boolean} action - Optional flag indicating whether to perform a specific action.
 * @returns {Promise<Object|null>} - Returns a Promise resolving to the response data if successful, otherwise null.
 */
const addOrUpdateAccess=async (id,access,action=undefined)=>{
  return handleAccessError(async () => {
    const response  = await axios.post(`${base_url}guard/api/admins/access/${id}/?${action?'action=true':''}`,access,conf("SUP"));
    return response.data;
  })
}

  const authService = {
    login,
    logout,
    register:newadministrator,
    getnotvalidateadmin,
    validate,
    validatesList,
    loadDatabaseAction,
    addOrUpdateAccess
  };
  
  export default authService;