import moment from "moment";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import presenceService from "../features/presence/presenceServices";
import { getUnreadViolation } from "../features/presence/presenceSlice";


/**
 * Handles access errors for asynchronous actions.
 *
 * @param {Function} action - Asynchronous action to be executed.
 * @returns {Promise<any>} - Returns a Promise resolving to the result of the provided action.
 * @throws {Error} - Throws an error if the action encounters a non-401 HTTP status code.
 */
export const handleAccessError = async (action) => {
  try {

    // Execute the provided asynchronous action and return its result.
    return await action()
  } catch (error) {

     // Check if the error is due to a 401 status code; if so, rethrow the error.
    if (error.response.status === 401) {
      throw error
    } else {
      // Rethrow the error for other non-401 status codes.
      throw error
    }
  }
}


/**
 * Converts a file to Base64 format.
 *
 * @param {File} file - File to be converted to Base64.
 * @returns {Promise<string>} - Returns a Promise resolving to the Base64 representation of the file.
 * @throws {Error} - Throws an error if there is an issue converting the file to Base64.
 */
export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Read the file as a data URL.
    reader.readAsDataURL(file);

    // Resolve with the Base64 representation when the file reading is successful.
    reader.onload = () => {
      if (reader.result) {
        const base64 = reader.result.toString().split(",")[1];
        resolve(base64);
      } else {

        // Reject with an error message if there is an issue with the conversion.
        reject("Error converting file to Base64");
      }
    };

    // Reject with an error if there is an issue reading the file.
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Asynchronously uploads a file by converting it to a Base64-encoded data URL.
 *
 * @param {File} file - The file to be uploaded.
 * @returns {Promise<string>} - Returns a Promise resolving to the Base64-encoded data URL of the uploaded file.
 * @throws {Error} - Throws an error if there is an issue converting the file to Base64.
 */
export const uploadFile = async (file) => {
  // Convert the file to Base64 format using the provided utility function.
  const base64 = await convertFileToBase64(file);
  
  // Construct and return the data URL with the file type and Base64-encoded data.
  return `data:${file.type};base64,${base64}`;
};


/**
 * Converts an object to a URL query string by encoding its key-value pairs.
 *
 * @param {Object} obj - The object to be converted to a query string.
 * @returns {string} - Returns the URL query string representation of the input object.
 */
export const objectToQueryString = (obj) => {
  // Array to store encoded key-value pairs.
  const keyValuePairs = [];

  // Iterate through the object's own properties.
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Encode each key and value and push them to the array.
      const value = obj[key];
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }
  }

  // Join the array into a single string with '&' as the separator.
  return keyValuePairs.join('&');
}


export function getRandomColor() {
  // Generate a random hex color code
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export const month = () => {
  return Array.from({ length: 12 }, (_, index) => {
    const date = new Date(2023, index, 1);
    return date.toLocaleString('en-us', { month: 'long' });
  });
}

export const guardNow = () => {
  return moment().format('YYYY-MM-DD')
}

export const dataToDataset = (data, label = (record) => record, value = (record) => record, otherconfig = {}) => {
  console.log(label(data))
  return {
    label: label(data),
    data: data.map((data) => value(data)),
    ...otherconfig
  }
}


export let getValue = (input, digits = 2) => {
  const patterns = [
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DD HH:mm:ss",
    "YYYY-MM-DD",
    'DD/MM/YYYY',
    'DD/MM/YYYY HH:mm:ss'
    // Add more patterns as needed
  ];
  const date = moment(input, patterns, true);
  const isValid = date.isValid();
  if (isValid) {
    /**
     * Certainly! Here are some common format options for formatting dates using Moment.js:
    
        "DD/MM/YYYY": Day, month, and year with two-digit day and month (e.g., 12/12/2023).
        "D MMM YYYY": Day, abbreviated month, and four-digit year (e.g., 12 Dec 2023).
        "MMMM Do, YYYY": Full month, day with ordinal suffix, and four-digit year (e.g., December 12th, 2023).
        "YYYY-MM-DD": Four-digit year, month, and day (e.g., 2023-12-12).
        "dddd, MMMM Do YYYY, h:mm:ss a": Full weekday, full month, day with ordinal suffix, four-digit year, time with AM/PM marker (e.g., Sunday, December 12th 2023, 9:30:45 am).
        "MMM Do, YYYY HH:mm": Abbreviated month, day with ordinal suffix, four-digit year, 24-hour time (e.g., Dec 12th, 2023 09:30).
     */
    return date.format("DD MMMM YYYY");
  }
  else {
    let za = input
    // if(!isNaN(Number.parseInt(input))){
    //     return input.toLocaleString();
    // }
    if (!isNaN(Number.parseFloat(input))) {
      const parsedFloat = parseFloat(input);
      const formattedFloat = parsedFloat.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
      return formattedFloat;

    }
    return input.toLocaleString();
  }
};

export function formatString(inputString) {
  let result = '';

  // Check if the input string has at least 3 characters
  if (inputString?.length < 3) {
    console.log("Input string should have at least 3 characters.");
    return result;
  }

  // Iterate through the input string and group characters in sets of 3
  for (let i = 0; i < inputString?.length; i += 3) {
    let group = inputString.slice(i, i + 3);
    result += group + ' ';
  }

  // Remove the trailing space and return the formatted string
  return result.trim();
}

/**
 * Generates a notification object based on any violation.
 *
 * @param {Array} data - Array of violation data.
 * @param {string} key - Unique key for the notification.
 * @returns {Object} - Notification object with key, message, description, and duration properties.
 */
export const getNotification = (data, key) => {

  // Map violation data to Single components to display in the notification.
  const message = data.map((violation) => {
    return (
      <Single violation={violation} />
    )
  })

  // Return the notification object with key, message, description, and duration properties.
  return {
    key,
    message: "Someone entered",
    description: <>{message}</>,
    duration: 0
  }
}


/**
 * Renders a Single violation entry within the notification.
 *
 * @param {Object} props - Props containing violation details.
 * @returns {JSX.Element} - Returns a JSX element representing a single violation entry.
 */
export const Single = (props) => {
  const { completename, roomname, established_at, detected_at, id } = props.violation
  const dispatch = useDispatch()

  // Render a single violation entry with mark as read functionality.
  return (
    <>
      <p><strong>{completename}</strong> entered in {roomname} at {detected_at} of {established_at} </p>
      <Button onClick={async () => {
        try {

          // Set the violation as read using the presenceService.
          await presenceService.setRead(id);
          dispatch(getUnreadViolation())
        } catch (error) {
          // Handle any errors that occur during the mark as read operation.
        }

      }
      }>Mark as read</Button>
      <hr />
    </>
  )
}

/**
 * Asynchronously checks the validity of a captcha value by sending a POST request to the captcha verification endpoint.
 *
 * @param {string} captchaValue - The captcha value to be verified.
 * @returns {Promise<boolean>} - Returns a Promise resolving to true if the captcha is valid, otherwise false.
 */
export const checkCaptcha = async  (captchaValue)=>{
  try{

     // Send a POST request to the captcha verification endpoint with the provided captchaValue.
    const res = await fetch(`${process.env.REACT_APP_CAPTCHA_URL}/verify`, {

      method: "POST",

      body: JSON.stringify({ captchaValue }),

      headers: {

        "content-type": "application/json",

      },})

      // Parse the response data as JSON.
      const data = await res.json();

      // Return true if the captcha verification is successful, otherwise false.
      return data.success ? true : false;

  }catch(err){
    // Handle errors and return false if an error occurs during the captcha verification.
    return false;
  }
}