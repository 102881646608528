import { Card } from "antd";
import { Button, Col, Row } from "react-bootstrap";
import style from "./RoomCard.module.css"
import bed from "../images/bedroom.png"
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
export const RoomCard = (props) => {
    const { room } = props;
    const navigate = useNavigate()
    return (
        <Card className={style.roomcard} bordered={false} style={{ width: 300, justifyContent: "center", minWidth: 300 }}>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <img className="img w-25" src={bed} />
                    <p className={style.room}>{room.roomname}</p>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <Button onClick={() => navigate(`${room.id}/${room.keyroom}/${slugify(room.roomname)}`)} >Access</Button>
                </Col>
            </Row>
        </Card>
    )
}