import axios from "axios";
import { conf, config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
import { handleAccessError, objectToQueryString } from "../../utils";
import { djangoLookups, lookUpsWithValue } from "../../utils/lookups";

const createCam = async (cam) => {
    return handleAccessError(async () => {
        const response = await axios.post(`${base_url}guard/api/camera/`, cam, conf("ATC"));
        return response.data;
    })
}

const updateCam =async (id,newdata)=>{
    return handleAccessError(async () => {
        const response = await axios.put(`${base_url}guard/api/camera/${id}/`,newdata, conf("ATC"));
        return response.data;
    });
}

const getCameraList = async (data=undefined)=>{
    let keys= {}
    if (data) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                lookUpsWithValue(keys, djangoLookups.icontains, key, data[key]);
            }
        }
    }
    
    // lookUpsWithValue(keys,null,"established_at",date)
    return handleAccessError(async () =>{
        const response = await axios.post(`${base_url}guard/api/camera/view/search/all/?${objectToQueryString(keys)}`,null,conf("ATC"));
        console.log(response.data);
        return response.data;
    })
}
const camService = {
     createCam,
     getCameraList,
     updateCam
  };
export default camService;