import React from "react";
import {  Modal } from "react-bootstrap"

const HandleModal=({show,title,onHide,children})=>{
  const footer = React.Children.toArray(children).find(
    (child) => child.type === HandleModal.Footer
  );
  const body=React.Children.toArray(children).filter((child) => child.type !== HandleModal.Footer)

    return (
        <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {body}
        </Modal.Body>
        {footer  && footer}
      </Modal>
    )
}
HandleModal.Footer=({children})=>{
  return (
    <Modal.Footer>
      {children}
    </Modal.Footer>
  )
}

export default HandleModal