import { Col, Row } from "react-bootstrap"
import CustomInput from "../CustomInput"
export const JobInfo = (props) => {
    const { formik, workstations } = props
    return (
        <>
            <h3>Job Information</h3>
            <Row className="mb-4">
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="email"
                        name="person.email"
                        label="Professional Email"
                        onChng={formik.handleChange("person.email")}
                        onBlr={formik.handleBlur("person.email")}
                        val={formik.values.person.email}
                    />
                    <div className="error">
                        {formik.touched.person?.email && formik.errors.person?.email}
                    </div>
                </Col>

            </Row>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <select
                        name="job.workstationid"
                        onChange={formik.handleChange("job.workstationid")}
                        onBlur={formik.handleBlur("job.workstationid")}
                        value={formik.values.job.workstationid}
                        className="form-control py-3 mb-3"
                        id="workstationid"
                    >
                        <option value="">Select A Job</option>
                        {workstations?.map((i, j) => {
                            return (
                                <option key={j} value={i.id}>
                                    {i.jobname}
                                </option>
                            );
                        })}
                    </select>
                    <div className="error">
                        {formik.touched.job?.workstationid && formik.errors.job?.workstationid}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="date"
                        name="job.hire_date"
                        label="Hired At"
                        onChng={formik.handleChange("job.hire_date")}
                        onBlr={formik.handleBlur("job.hire_date")}
                        val={formik.values.job.hire_date}
                    />
                    <div className="error">
                        {formik.touched.job?.hire_date && formik.errors.job?.hire_date}
                    </div>
                </Col>
            </Row>
        </>
    )
}