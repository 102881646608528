import React from 'react';
import ReduxFormSave from '../components/handler/save/ReduxFormSave';
import formService from '../features/form/formServices';
import { createroom } from '../features/room/roomSlice';
import { Helmet } from 'react-helmet-async';

export const AddRoom = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>New Room </title>
            </Helmet>
            <section>
                <h1>Add a new Room </h1>
            </section>
            <hr />
            <section>
                <ReduxFormSave savemethod={createroom} reducerkey={"room"} loadfield={async () => { return await formService.loadsave("api/room") }}></ReduxFormSave>
            </section>

        </React.Fragment>
    )
}