import React from 'react';
import ReduxFormSave from '../components/handler/save/ReduxFormSave';
import formService from '../features/form/formServices';
import { createcam } from '../features/cam/camSlice';
import { conf } from '../utils/axiosconfig';

export const AddCamera = () => {

    return (
        <React.Fragment>
            <section>
                <h1>Add a new camera </h1>
            </section>
            <hr />
            <section>
                <ReduxFormSave savemethod={createcam} reducerkey="cam" loadfield={async () => { return await formService.loadsave("guard/api/camera", conf("ATC")) }}></ReduxFormSave>
            </section>

        </React.Fragment>
    )
}