import axios from "axios"
export const fetchRequestToken = async (Successaction, Erroraction, url, data, tokenable) => {
  let us = `${url}`
  let x = axios
  if (tokenable) {
    x.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
  }
  if (data !== undefined) {
    await x
      .post(us, data)
      .then((response) => {
        Successaction(response)
      })
      .catch((error) => {
        // console.log(error)
        Erroraction(error)
      })
  } else {
    await x
      .post(us)
      .then((response) => {
        // console.log(response.data.data)
        Successaction(response)
      })
      .catch((error) => {
        Erroraction(error)
        // setIsLoading(false)
      })
  }
}
export const fetchGet = async (Successaction, Erroraction, url, data, tokenable) => {
  let us = `${url}`
  let x = axios
  if (tokenable) {
    x.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
  }
  if (data !== undefined) {
    await x
      .get(us, data)
      .then((response) => {
        Successaction(response)
      })
      .catch((error) => {
        // console.log(error)
        Erroraction(error)
      })
  } else {
    await x
      .get(us)
      .then((response) => {
        // console.log(response.data.data)
        Successaction(response)
      })
      .catch((error) => {
        Erroraction(error)
        // setIsLoading(false)
      })
  }
}

export const fetchApiData=(method,url,headers,data,Successaction, Erroraction)=>{
  const config = {
    method: method,
    url: url,
  };

  if(data){
    config.data = data
  }

  if(headers){
    config.headers = headers
  }
  
  axios.request(config)
    .then(response => {
      Successaction(response)
    })
    .catch(error => {
      Erroraction(error)
    });
}


export let formatValue = (input, currency) => {
  if(input===undefined){
    return ""
  }else{
    if (Number.isInteger(parseFloat(input))) {
      return parseFloat(input).toLocaleString(undefined, {
        useGrouping: true
      });
    } else {
      if (!isNaN(Number.parseFloat(input))) {
        const parsedFloat = parseFloat(input);
        const formattedFloat = parsedFloat.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        });
        return formattedFloat;
      }
    }
    return currency ? `${input.toLocaleString()} ${currency}` : input.toLocaleString();
  }

};


export const change=(za)=>{
  if(za.fields.type===null){
      za.fields.type=undefined;
  }
  if(za.fields.list===null){
      za.fields.list=undefined;
  }
}