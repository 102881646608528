import React from "react";
// import { Field } from "./ScSave";
import Form from 'react-bootstrap/Form';
import CustomInput from "../../CustomInput";

const ScInput=({fields,datas,label,datalabelkey,datanamekey,moreprops,children})=>{
    const labs=(label && <Form.Label>{label}</Form.Label>) || <Form.Label>{fields.fieldname}</Form.Label>;
    if (fields.type === 'arrayOption'
     && datas!==undefined
     && datalabelkey!==null
      && datanamekey!==null) {
        // Build a select option tag
        const options = datas.map((data) => {
          const label = data[datalabelkey]
          const value = data[datanamekey]
          return (
            <option key={value} value={value}>
              {label}
            </option>
          )
        })
        console.log(moreprops)
        return <>{labs}<Form.Select className="mb-3" name={fields.fieldname} {...moreprops}>{options}</Form.Select></>
      } else if ((fields.list !== undefined) && datas!==undefined && datalabelkey!==null && datanamekey!==null) {
        // Build a checkbox or radio component
        const components = datas.map((data,index) => {
          const label = data[datalabelkey]
          const value = data[datanamekey]
          return (
            <>
            <Form.Check key={index} inline type={fields.list} name={fields.fieldname} label={label} value={value} {...moreprops}></Form.Check>
            {children}
            </>
          );
        })
        
        return <>{labs}{components}</>;
      } 
      else if(fields.type==='textarea'){
        return (<>
        {labs}
        <Form.Control className="mb-3" as="textarea" name={fields.fieldname} {...moreprops} />
        {children}
        </>);
      }
      else {
        // Build a regular input field
        return (<>
        {labs}
        <Form.Control className="mb-3"  type={fields.type} name={fields.fieldname} {...moreprops} />
        {children}
        </>);
      }
}
export default ScInput;