import axios from "axios";
import { conf, config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
import { handleAccessError, month, objectToQueryString } from "../../utils";
import moment from "moment";
import { lookUpsWithValue } from "../../utils/lookups";

const createWorkstation = async (workstation) => {
    return handleAccessError(async () => {
        const response = await axios.post(`${base_url}api/workstation/`, workstation, config);
        return response.data;
    })
}

const getAllWorkstations = async () => {
    return handleAccessError(async () => {
        const response = await axios.get(`${base_url}api/workstation/all/`, config);
        return response.data;
    })
}


const getStatistic = (data = { year: moment().year(), delays: true, date: moment().format("YYYY-MM-DD") }) => {
    let keys = {}
    lookUpsWithValue(keys, null, "year", data.year)
    lookUpsWithValue(keys, null, "delays", data.delays)
    lookUpsWithValue(keys, null, "date", data.date)
    return handleAccessError(async () => {
        const response = await axios.get(`${base_url}guard/api/detection/stat/workstation/?${objectToQueryString(keys)}`, conf("SUP"));
        return response.data;
    })
}

const transformTo12 = (data) => {
    let retour = init12();
    console.log("data", data);

    for (let r = 0; r < 12; r++) {
        for (let i = 0; i < data.length; i++) {
            retour[r] = {...data[i], ...retour[r] };
            if (data[i]["month"] === retour[r].month) {
                retour[r]["result"] = Number(data[i]["result"]) + Number(retour[r].result);
            }
        }
    }
    console.log(retour)
    return retour;
};


export const merge = (data) => {
    let retour = []
    for (let array of data) {
        for (let element of array) {
            retour.push(element)
        }
    }
    return retour
}

const init12 = () => {
    const retour = []
    for (let i = 1; i <= 12; i++) {
        retour.push({
            month: i,
            result: 0
        })
    }
    return retour
}
const workstationService = {
    createWorkstation,
    getAllWorkstations,
    getStatistic,
    transformTo12
};
export default workstationService;