import { useDispatch, useSelector } from "react-redux"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { getWorkingEmployees } from "../features/employe/employeSlice"
import { Avatar, Button as Btn, Button, Image, Input } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

const { Search } = Input;
export const ListEmployee = () => {
    const employee_state = useSelector((state) => state.employee)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getWorkingEmployees())
    }, [])

    const onSearch = (value, _e, info) => {
        dispatch(getWorkingEmployees(value))
    }
    // retrieve the liste
    const { employees } = employee_state
    return (
        <MainWaitComponent isLoading={employee_state.isLoading}>
            <MainWaitComponent.ErrorPage error={employee_state.isError} status={employee_state.errorstatus}>
                <Container>
                    <Row>
                        <Col>
                            <Button onClick={() => navigate(-1)}>Back</Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <p className="text-info"><strong>Employees ({employees.length})</strong></p>
                        <Search placeholder="input search text" onSearch={onSearch} enterButton />
                    </Row>
                    <Row className="mb-3 mt-3">
                        <Col lg="12" md="12" sm="12">
                            <Button type="primary" size="small" onClick={() => navigate("/watch/employee")}>Add new Employee</Button>
                        </Col>
                    </Row>
                    <Row>
                        {
                            employees.map((employee, index) => {
                                return (
                                    <Col lg="3" md="4" sm="12" key={index} className="mb-2">
                                        <Card style={{ width: '18rem' }}>
                                            {/* <Card.Img variant="top" style={{ maxHeight: "150px", }} src={employee.image} /> */}
                                            <span className="p-2">
                                                <Avatar
                                                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                                    src={employee?.image}
                                                >{employee?.firstname}
                                                </Avatar>
                                            </span>
                                            <Card.Body>
                                                <Card.Title>{employee.firstname}</Card.Title>
                                                <Btn type="primary" onClick={() => navigate(`${employee.id}/${slugify(`${employee.firstname} ${employee.surname}`)}`)}>Details</Btn>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}