import { Col, Container, Row } from "react-bootstrap"
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../components/CustomInput";
import { AddButton } from "../components/AddButton";
import { register } from "../features/auth/authSlice";
import { useEffect } from "react";
export const Register=()=>{

    const dispatch = useDispatch()

    let schema = yup.object().shape({
            name: yup.string().required("Your name is required"),
            email: yup.string().required("Email is required").email("Email only"),
            password: yup.string().required("Password is required").min(6),
            confirmPassword: yup.string().required("Confirm password is required").oneOf([yup.ref("password"), null], "Passwords do not match"),
    
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            values["confirmPassword"]=undefined
            console.log(JSON.stringify(values))
            await dispatch(register(values))
        }
    });

    const newobject = useSelector((state) => state.auth);
    const {
      isSuccess,
      isError,
      isLoading,
    } = newobject;
    useEffect(() => {
        if (isSuccess && newobject[`createduser`]){
          toast.success(newobject['message']);
        }
        // if (isSuccess && updatedBoutique) {
        //   toast.success("Category Updated Successfullly!");
        //   navigate("/admin/list-category");
        // }
        if (isError) {
          toast.error("Something Went Wrong!");
        }
      }, [isSuccess, isError, isLoading, newobject]);
    return (
        <Container>
            <section>
                <h1>Register</h1>
            </section>
            <hr/>
            <form action="" onSubmit={formik.handleSubmit}>
            <section>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="text"
                        name="name"
                        label="Your name"
                        onChng={formik.handleChange("name")}
                        onBlr={formik.handleBlur("name")}
                        val={formik.values.name}
                    />
                    <div className="error">
                        {formik.touched.name && formik.errors.name}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="email"
                        name="email"
                        label="Your email"
                        onChng={formik.handleChange("email")}
                        onBlr={formik.handleBlur("email")}
                        val={formik.values.email}
                    ></CustomInput>
                    <div className="error">
                        {formik.touched.email && formik.errors.email}
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="password"
                        name="password"
                        label="Password"
                        onChng={formik.handleChange("password")}
                        onBlr={formik.handleBlur("password")}
                        val={formik.values.password}
                    />
                    <div className="error">
                        {formik.touched.password && formik.errors.password}
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <CustomInput
                        type="password"
                        name="confirmPassword"
                        label="Comfirm password"
                        onChng={formik.handleChange("confirmPassword")}
                        onBlr={formik.handleBlur("confirmPassword")}
                        val={formik.values.confirmPassword}
                    />
                    <div className="error">
                        {formik.touched.confirmPassword && formik.errors.confirmPassword}
                    </div>
                </Col>
            </Row>
            <Row>
                <AddButton loading={isLoading} className={"btn btn-success border-0 rounded-3 my-5"}>
                        Register
                </AddButton>
            </Row>
            </section>
            </form>
        </Container>
    )
}