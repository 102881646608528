import axios from "axios";
import { handleAccessError, objectToQueryString } from "../../utils";
import { djangoLookups, lookUpsWithValue } from "../../utils/lookups"
import moment from 'moment';
import { base_url } from "../../utils/baseUrl";
import { conf } from "../../utils/axiosconfig";
const getPresence = async ({date,criteria})=>{
    console.log("za",date,criteria);
    let keys={}
    lookUpsWithValue(keys,null,"established_at",date)
    if(criteria!==0){
        if(criteria>=3){
            lookUpsWithValue(keys,null,"leftearly",1)
            lookUpsWithValue(keys,null,"islate",1)
        }
        else if(criteria==2){
            lookUpsWithValue(keys,null,"leftearly",1)
        }else if(criteria==1){
            lookUpsWithValue(keys,null,"islate",1)
        }
    }
    return handleAccessError(async () => {
        const response  = await axios.post(`${base_url}guard/api/attendance/search/all/?${objectToQueryString(keys)}&strict=true`,null,conf("CEP"));
        return response.data;
      })
}

const getMissingDays = async (date=moment().format('YYYY-MM-DD'))=>{
    let keys= {}
    lookUpsWithValue(keys,null,"established_at",date)
    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/missingdate/search/all/?${objectToQueryString(keys)}`,null,conf("CEP"))
        return response.data
    })

}

const getHistory=async (date=moment().format('YYYY-MM-DD'))=>{
    let keys={}
    lookUpsWithValue(keys,null,"established_at",date)
    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/detection/search/all/?${objectToQueryString(keys)}`,null,conf("CEP"))
        return response.data
    })
}

const getTop = async (data=undefined)=>{
    let keys={}
    if(data?.year){
        lookUpsWithValue(keys,null,"year",data?.year)
    }
    if(data?.month){
        lookUpsWithValue(keys,null,"month",data?.month)
    }
    if(data?.top){
        lookUpsWithValue(keys,null,"top",data?.top)
    }
    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/presence/top/search/all/?${objectToQueryString(keys)}&strict=true`,null,conf("CEP"))
        return response.data
    })
}

const saveManually=async  (data)=>{
    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/detection/manual/`,data,conf("CEP"))
        return response.data
    })
}

const getHistoryEmployees=async (data)=>{
    let keys={}
    if(data){
        if(data.established_at)
            lookUpsWithValue(keys,null,"established_at",data.established_at)
        if(data.week){
            console.log(data.week)
            lookUpsWithValue(keys,djangoLookups.week,"established_at",data.week)
        }
        if(data.year){
            lookUpsWithValue(keys,djangoLookups.year,"established_at",data.year)

        }
        if(data.from){
            lookUpsWithValue(keys,djangoLookups.gte,"established_at",data.from)
        }
        if(data.to){
            lookUpsWithValue(keys,djangoLookups.lt,"established_at",data.to)
        }
        if(data.fromtime){
            lookUpsWithValue(keys,djangoLookups.gte,"detected_at",data.fromtime)
        }
        if(data.totime){
            lookUpsWithValue(keys,djangoLookups.lt,"detected_at",data.totime)
        }
    }
    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/detection/employee/search/all/?${objectToQueryString(keys)}&strict=true`,null,conf("SUP,CEP,ATC"))
        return response.data
    });
}

const getDectectionPerHour= async (data=undefined)=>{
    let keys={}
    let a=undefined
    if(data?.from){
        a=moment(data.from)
        lookUpsWithValue(keys,null,"month",a.format("MM"))
        lookUpsWithValue(keys,null,"year",a.format("YYYY"))
    }
    else if(data?.to){
        a=moment(data.to)
        lookUpsWithValue(keys,null,"month",a.format("MM"))
        lookUpsWithValue(keys,null,"year",a.format("YYYY"))
    }else{
        a=moment()
        lookUpsWithValue(keys,null,"month",a.format("MM"))
        lookUpsWithValue(keys,null,"year",a.format("YYYY"))
    }

    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/detection/perhour/search/all/?${objectToQueryString(keys)}&strict=true`,null,conf("SUP,CEP,ATC"))
        console.log(response.data)
        return response.data
    });
    
}
const getUnreadViolation = async (status=0)=>{
    let keys={}
    lookUpsWithValue(keys,null,"status",status)
    return handleAccessError(async ()=>{
        const response = await axios.post(`${base_url}guard/api/dectection/violation/search/all/?${objectToQueryString(keys)}&strict=true`,null,conf("SUP,CEP,ATC"))
        console.log(response.data)
        return response.data
    });
}

const setRead = async (id)=>{
    return handleAccessError(async ()=>{
        const response = await axios.put(`${base_url}guard/api/violation/${id}/`,{status:1},conf("SUP,CEP,ATC"))
        console.log(response.data)
        return response.data
    });
}

const presenceService={
    getPresence,
    getMissingDays,
    getHistory,
    getHistoryEmployees,
    getDectectionPerHour,
    getTop,
    setRead,
    getUnreadViolation,
    saveManually
}
export default presenceService