import React from "react";
import { Spinner } from "react-bootstrap";

export const AddButton = ({ loading, children, className }) => {
  const buttonClassName = className || "btn btn-success border-0 rounded-3 my-5";

  return (
    <button className={buttonClassName} type="submit">
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        React.Children.map(children, (child) => {
          return child;
        })
      )}
    </button>
  );
};
