import axios from "axios";
import { conf, config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
import { handleAccessError,objectToQueryString } from "../../utils";
import { djangoLookups, lookUpsWithValue } from "../../utils/lookups";

const createRoom = async (room) => {
    return handleAccessError(async () => {
        const response = await axios.post(`${base_url}api/room/`, room, config);
        return response.data;
    })
}


const getRooms = async (roomname=undefined,page=1,page_size=5) => {
    let keys={}
    lookUpsWithValue(keys,djangoLookups.icontains,"roomname",roomname)
    return handleAccessError(async () => {
        let url = `${base_url}api/room/search/${roomname?`?${objectToQueryString(keys)}&`:'?'}`
        const response = await axios.get(`${url}page=${page}&page_size=${page_size}`, config);
        return response.data;
    })
}

const addRestriction = async (room,list)=>{
    return handleAccessError(async () =>{
        const response = await axios.post(`${base_url}guard/api/room/restriction/access/${room}/`,list,conf("SUP"))
        return response.data
    })
}

const getRoomRestriction = async (room=undefined)=>{
    let keys= {}
    if(room){
        lookUpsWithValue(keys,null,"room",room)
    }
    return handleAccessError(async ()=>{
        const response = await axios.get(`${base_url}guard/api/room/restriction/employee/search/all/?${objectToQueryString(keys)}`,conf("SUP"))
        return response.data
    })
}
const roomService = {
    createRoom: createRoom,
    getRooms: getRooms,
    addRestriction,
    getRoomRestriction
  };
export default roomService;