import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDectectionPerHour, getHistoryEmployees } from "../features/presence/presenceSlice"
import moment from "moment"
import { AdminTag } from "../components/admin/AdminTag"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Accordion, Button, Col, Container, Row } from "react-bootstrap"
import { Card, Result, Table } from "antd"
import { CustomChart } from "../components/chart/Chart"
import { StatisticBoard } from "../components/presence/StatisticBoard"
import { FrownTwoTone, SmileTwoTone } from "@ant-design/icons"

import * as yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../components/CustomInput"
import { CSVLink } from "react-csv"


export const ListDetection = () => {
    const dispatch = useDispatch()
    const presenceState = useSelector((state) => state.presence)
    const { histories, isLoading } = presenceState

    useEffect(() => {
        dispatch(getHistoryEmployees({ week: moment().week(), year: moment().year(), }))
        dispatch(getDectectionPerHour())
    }, [])
    // columns
    const csvcolumn = [
        {
            label: "Full Name",
            key: "completename",
        },
        {
            label: "Date",
            key: "established_at",
        },
        {
            label: " Room",
            key: "roomname",
        }, {
            label: "Detected",
            key: "state",
        }
    ]
    const columns = [
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename",
            fixed: 'left',
            width: 200,
            render: (_, { state, completename }) => {
                if (state === 0) {
                    return <p className="text-danger"><strong>Unknown</strong> </p>
                } else {
                    return completename
                }
            }
        },

        {
            title: "Date",
            dataIndex: "established_at",
            key: "established_at",
            render: (_, { established_at, detected_at }) => {
                return `${established_at} ${detected_at}`
            }

        },
        {
            title: " Room",
            dataIndex: "roomname",
            key: "roomname",
            render: (_, { roomname }) => {
                if (roomname !== null) {
                    return `${roomname}`
                } else {
                    return <p className="text-danger"><strong>Unknown Source</strong> </p>
                }
            }
        },
        {
            title: "Detected",
            dataIndex: "state",
            key: "state",
            fixed: 'right',
            width: 100,
            render: (_, { state }) => {
                return <AdminTag.DetectionStatus status={state} />
            }
        }
    ]


    // schema for search
    let schema = yup.object().shape({
        from: yup.string(),
        to: yup.string(),
        fromtime:yup.string(),
        totime:yup.string(),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            from: moment().add(-1, "day").format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            fromtime:undefined,
            totime:undefined
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            dispatch(getHistoryEmployees(values))
            dispatch(getDectectionPerHour({from:values.from,to:values.to}))

        }
    })
    // data display
    const data = histories
    const total = data.reduce((accumulator, currentObject) => accumulator + currentObject.state, 0);
    const process = (data) => {
        let retour = [0, 0]
        for (let i = 0; i < data.length; i++) {
            if (data[i].state === 1) {
                retour[0] += 1
            } else {
                retour[1] += 1
            }
        }
        return retour
    }
    const labels = ["Detected", "Unknown"]
    const dataForsets = process(data)
    const datasets = [
        {
            label: "History Graph",
            data: dataForsets,
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
                'rgb(75, 192, 192)',
                'rgb(255, 99, 132)',
            ],
            borderWidth: 1

        }
    ]
    return (
        <MainWaitComponent isLoading={isLoading}>
            <MainWaitComponent.ErrorPage error={presenceState.isError} status={presenceState.errorstatus}>
                <Container>
                    <Row>
                        <h1>Detection History</h1>
                    </Row>
                    <hr />

                    <Row className="mb-3">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Search</Accordion.Header>
                                <Accordion.Body>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row>
                                            <Col lg="6" md="12" sm="12">
                                                <CustomInput
                                                    type="date"
                                                    name="from"
                                                    label="From"
                                                    onChng={formik.handleChange("from")}
                                                    onBlr={formik.handleBlur("from")}
                                                    val={formik.values.from}
                                                />
                                            </Col>
                                            <Col lg="6" md="12" sm="12">
                                                <CustomInput
                                                    type="date"
                                                    name="to"
                                                    label="To"
                                                    onChng={formik.handleChange("to")}
                                                    onBlr={formik.handleBlur("to")}
                                                    val={formik.values.to}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6" md="12" sm="12">
                                                <CustomInput
                                                    type="time"
                                                    name="fromtime"
                                                    label="At"
                                                    onChng={formik.handleChange("fromtime")}
                                                    onBlr={formik.handleBlur("fromtime")}
                                                    val={formik.values.fromtime}
                                                />
                                            </Col>
                                            <Col lg="6" md="12" sm="12">
                                                <CustomInput
                                                    type="time"
                                                    name="totime"
                                                    label="To"
                                                    onChng={formik.handleChange("totime")}
                                                    onBlr={formik.handleBlur("totime")}
                                                    val={formik.values.totime}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Button className="mt-2" variant="primary" type="submit">Search</Button>
                                        </Row>
                                    </form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>

                    <Row className="mb-2">
                        <Col lg="12" md="12" sm="12">
                            <Table columns={columns}
                                title={() => {
                                    return (
                                        <Row>
                                            <Col lg="10" md="6" sm="12">
                                                Here you can view  the workforce reports and export them for backup purpose or further analysis
                                            </Col>
                                            <Col lg="2" md="6" sm="12">

                                                <CSVLink 
                                                    target="_blank" filename={`savedHistory${moment().format("YYYYMMDD")}.csv`} data={data} headers={csvcolumn}>
                                                    EXPORT FILE
                                                </CSVLink>
                                            </Col>
                                        </Row>
                                    )
                                }}
                                dataSource={data} scroll={{
                                    x: 1300,
                                    y: 250
                                }}></Table>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="12" sm="12">
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <Card bordered={false} size="small">
                                        <CustomChart.Bar labels={labels} datasets={datasets}></CustomChart.Bar>
                                    </Card>
                                </Col>
                                <Col lg="12" md="12" sm="12" className="mt-3">
                                    <Row>
                                        <Col lg="6" md="12" sm="12">
                                            <StatisticBoard.Custom style={{ color: '#1a9609' }} title={"Total Detections"} value={total} />
                                        </Col>
                                        <Col lg="6" md="12" sm="12">
                                            <StatisticBoard.Custom style={{ color: '#1a9609' }} title={"Detection/per Hour"} value={presenceState.perhour ? presenceState.perhour[0]?.avgdetectionperminute : 0.00} />
                                        </Col>
                                        <Col lg="12" md="12" sm="12" className="mt-3">
                                            <Card bordered={false}>
                                                <Result
                                                    icon={dataForsets[0] < dataForsets[1] ? <FrownTwoTone /> : <SmileTwoTone />}
                                                    title={dataForsets[0] > dataForsets[1] ? "Guard Watch knows Everyone" : "Too much stranger"}
                                                ></Result>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="6" md="12" sm="12">
                            <Row>
                                <Col lg="12" md="12" sm="12" className="mt-3">
                                    <Card bordered={false}>
                                        <CustomChart.Doughnut labels={labels} datasets={datasets} styling={{ width: 80, height: 40 }}></CustomChart.Doughnut>
                                    </Card>
                                </Col>
                                <Col lg="12" md="12" sm="12" className="mt-3">
                                    <Card bordered={false}>
                                        <h3 className="text-center">You reach the bottom </h3>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}