// Retrieve user token from local storage if available, otherwise set it to null.
const getTokenFromLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

/**
 * Configuration object for HTTP headers including Authorization which is the token.
 */
export const config = {
  headers: {
    Authorization: `Bearer ${
      getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
    }`,
    Accept: "application/json",
  },
};

/**
 * Configuration object for HTTP headers including Authorization(token+ action).
 */
export const conf=(action)=>{
  return {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
      Action: action
    },
  }
}