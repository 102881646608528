import { Spin } from "antd";
import "./index.css";
import { ErrorPage } from "../error/ErrorPage";
export const MainWaitComponent = (props) => {
    const { isLoading, children } = props;
    return (
        <>
            {
                isLoading ? (<div className="loading">
                    <Spin size="large" />
                </div>) : <>{children}</>
            }
        </>
    )
}

MainWaitComponent.ErrorPage = (props) => {
    const { error, children } = props
    console.log(error,"error")
    return (
        <>
            {
                error ? (
                    <ErrorPage status={props.status?props.status:500} action={() => window.location.href = "/"}></ErrorPage>
                ) : (<>{ children }</>)
            }
        </>
    )
}