import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const loadsave = async (model_url,configuration=config)=>{
    const response = await axios.get(`${base_url}${model_url}/loadsave/`,configuration)
    console.log(response.data)
    return response.data;
}

const formService = {
    loadsave
  };
  
  export default formService;