import axios from "axios"
import { handleAccessError, objectToQueryString } from "../../utils"
import { base_url } from "../../utils/baseUrl"
import { conf, config } from "../../utils/axiosconfig"
import { djangoLookups, lookUps, lookUpsWithValue } from "../../utils/lookups"
import moment from "moment"

const loadData = async () => {
    return handleAccessError(async () => {
        const response = await axios.get(`${base_url}guard/api/employe/load/`, conf("CEP"))
        return response.data
    })
}
const createEmploye = async (employee) => {
    return handleAccessError(async () => {
        const response = await axios.post(`${base_url}guard/api/employe/save/`, employee, conf("CEP"));
        return response.data;
    })
}

const getWorkingEmployee = async (data = undefined) => {
    let keys={}
    if (data !== undefined) {
        lookUpsWithValue(keys, djangoLookups.icontains, "surname", data)
        lookUpsWithValue(keys, djangoLookups.icontains, "firstname", data)
        lookUpsWithValue(keys, djangoLookups.icontains, "email", data)
    }
    return handleAccessError(async () => {
        const response = await axios.post(`${base_url}guard/api/employe/view/search/all/?${objectToQueryString(keys)}`, null, conf("CEP,SUP"))
        return response.data
    })
}

const getRetrieve = async (id)=>{
    return handleAccessError(async () => {
        const response = await axios.get(`${base_url}guard/api/employe/view/${id}/`, conf("SUP,CEP"));
        return response.data;
    })
}
const getEmployeeStat = async (data = { year: moment().year(), month: moment().month()+1, id: undefined }) => {
    let keys = {}
    if (data.id) {
        lookUpsWithValue(keys, null, "year", data.year)
        lookUpsWithValue(keys, null, "month", data.month)
        lookUpsWithValue(keys, null,"employee", data.id)
    }
    return handleAccessError(async () => {
        const response = await axios.get(`${base_url}guard/api/detection/stat/employee/?${objectToQueryString(keys)}&strict=true`, conf("SUP,CEP"));
        return response.data;
    })

}

const getOvertimeEmployee = async (data = { year: moment().year(), month: moment().month()+1})=>{
    let keys = {}
        lookUpsWithValue(keys, null, "year", data.year)
        lookUpsWithValue(keys, null, "month", data.month)
        if(data.from){
            lookUpsWithValue(keys, djangoLookups.gte, "overtimepermonth", data.from)
        }
        if(data.to){
            lookUpsWithValue(keys, djangoLookups.lt, "overtimepermonth", data.to)
        }
        return handleAccessError(async () => {
            const response = await axios.get(`${base_url}guard/api/employe/overtime/search/all/?${objectToQueryString(keys)}&strict=true`, conf("SUP,CEP"));
            return response.data;
        })

}
const employeService = {
    loadData,
    createEmploye,
    getRetrieve,
    getEmployeeStat,
    getWorkingEmployee,
    getOvertimeEmployee
}
export default employeService