import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getCam, updateCam } from "../features/cam/camSlice";
import { toast } from "react-toastify";
import { Button, Col, Row } from "react-bootstrap";
import { MainWaitComponent } from "../components/wait/MainWaitComponent";
import { Button as AntBtn, Table } from "antd";
import { useNavigate } from "react-router-dom";
import HandleModal from "../components/handler/modal/HandleModal";
import ReduxFormUpdate from "../components/handler/update/ReduxFormUpdate";
import formService from "../features/form/formServices";
import { conf } from "../utils/axiosconfig";
import { Input } from 'antd';
import { Helmet } from "react-helmet-async";
const { Search } = Input;
export const ListCamera = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state.cam)
    const navigate = useNavigate()
    const [selected, setSelected] = useState()
    const [showUpdate, setShow] = useState(false)
    const { cams, isLoading, isError, updatedcamera } = state;

    useEffect(() => {
        dispatch(getCam())
    }, [])
    useEffect(() => {
        if (isError)
            toast.error("An Error has occurred")
    }, [isError])
    useEffect(() => {
        if (updatedcamera) {
            dispatch(getCam())
        }
    }, [updatedcamera])
    const columns = [
        {
            title: "Key",
            dataIndex: "cam_key",
            key: "cam_key"
        },
        {
            title: "Camera Name",
            dataIndex: "cameraname",
            key: "cameraname"
        }, {
            title: "Room",
            dataIndex: "roomname",
            key: "roomname"
        }, {
            title: "Action",
            render: (text, record) => {
                return (
                    <Button onClick={() => {
                        setSelected(record)
                        setShow(true)
                    }} variant="success">Update</Button>
                )
            }
        }
    ]
    const data = cams.map((item, index) => {
        return {
            id: item.id,
            cam_key: item.cam_key,
            cameraname: item.cameraname,
            roomname: item.roomname,
            roomid: item.roomid,
            accesstool: item.accesstool
        }
    })
    const onSearch = async (value, _e, info) => {
        // console.log(info?.source, value);
        dispatch(getCam({ cameraname: value, roomname: value }))
    }
    return (
        <MainWaitComponent isLoading={isLoading}>
            <MainWaitComponent.ErrorPage error={isError} status={state.errorstatus}>
                <Row>
                    <h1>List Of Camera</h1>
                </Row>
                <hr />
                <Row className="mb-2">
                    <Col lg="12" md="12" sm="12">
                        <p>Search</p>
                    </Col>
                    <Col lg="12" md="12" sm="12">
                        <Search placeholder="camera name,room" onSearch={onSearch} enterButton />
                    </Col>
                </Row>
                <Row>

                    <Table title={() => {
                        return (
                            <AntBtn
                                onClick={() => navigate("/watch/cam")}
                                type="primary"
                                style={{
                                    marginBottom: 16,
                                    width: "100px"
                                }}
                            >
                                Add a row
                            </AntBtn>
                        )
                    }} columns={columns} dataSource={data}></Table>
                    <HandleModal onHide={() => setShow(false)} show={showUpdate} title={"Update Camera"}>
                        <ReduxFormUpdate data={selected} dataid={selected?.id} updatemethod={updateCam} reducerkey="cam" loadfield={async () => { return await formService.loadsave("guard/api/camera", conf("ATC")) }} ></ReduxFormUpdate>
                    </HandleModal>
                </Row>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )

}