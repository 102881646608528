import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import roomService from "./roomServices";

export const createroom= createAsyncThunk(
    "rooms/create-room",
    async (room, thunkAPI) => {
        try{
            return await roomService.createRoom(room)
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getRooms= createAsyncThunk(
    "rooms/get-rooms",
    async (data, thunkAPI) => {
      try{
        return await roomService.getRooms(data.roomname,data.page,data.page_size,data.next,data.prev)
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
)
export const addRestriction= createAsyncThunk(
  "rooms/add-restriction",
  async (data, thunkAPI) => {
    try{
       return await  roomService.addRestriction(data.id,data.list)
    }catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const getRoomRestriction=createAsyncThunk(
  "rooms/get-room-restriction",
  async (data, thunkAPI) => {
    try{
      return await roomService.getRoomRestriction(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
  }
)
export const resetState = createAction("Reset_all");


const initialState = {
    rooms: {
      count:0,
      next:null,
      previous:null,
      result:[]
    },
    restrictions:[],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
  };
  
  export const roomSlice=createSlice({
    name:"rooms",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
      .addCase(createroom.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createroom.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdroom = action.payload;
      })
      .addCase(createroom.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(addRestriction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRestriction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addedrestriction = action.payload;
      })
      .addCase(addRestriction.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getRooms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRooms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.rooms = action.payload;
      })
      .addCase(getRooms.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getRoomRestriction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoomRestriction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.restrictions = action.payload;
      })
      .addCase(getRoomRestriction.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
    }
})


export default roomSlice.reducer;