/**
 * Dictionary of Django lookups with their corresponding values.
 */
const djangoLookups = {
    contains: "contains",
    icontains: "icontains",
    exact: "exact",
    iexact: "iexact",
    in: "in",
    gt: "gt",
    gte: "gte",
    lt: "lt",
    lte: "lte",
    startswith: "startswith",
    istartswith: "istartswith",
    endswith: "endswith",
    iendswith: "iendswith",
    range: "range",
    date: "date",
    year: "year",
    month: "month",
    day: "day",
    week_day: "week_day",
    week:"week",
    hour: "hour",
    minute: "minute",
    second: "second",
    isnull: "isnull",
    regex: "regex",
    iregex: "iregex"
  };

  /**
 * Constructs a Django lookup string based on the provided lookup type and field.
 *
 * @param {string} lookups - Type of lookup.
 * @param {string} field - Field to apply the lookup on.
 * @returns {string} - Returns the constructed Django lookup string.
 */
const lookUps=(lookUps,field)=>{
    if(lookUps===undefined || lookUps===null){
        return `${field}`    
    }
    return `${field}__${djangoLookups[lookUps]}`
}

/**
 * Adds a Django lookup with a specified value to the provided data object.
 *
 * @param {Object} data - The data object to add the lookup to.
 * @param {string} lookups - Type of lookup.
 * @param {string} field - Field to apply the lookup on.
 * @param {any} value - Value to be associated with the lookup.
 */
const lookUpsWithValue=(data,lookups,field,value)=>{
    data[lookUps(lookups,field)] = value
}

/**
 * Adds multiple Django lookups with their specified values to the provided data object.
 *
 * @param {Object} data - The data object to add the lookups to.
 * @param {Array} array_search - Array of objects containing lookups, fields, and values.
 */
const lookUpsFromArray=(data,array_search)=>{
    for (let i = 0; i < array_search.length; i++) {
        // let {lookups,field,value} = array_search[i]
        let keys = Object.keys(array_search[i])
        let field=null
        let value=null
        let lookups=null
        for(let key of keys){
            if(key === 'lookups'){
                lookups = array_search[i][key]
            }else{
                field = key
                value = array_search[i][key]
            }
        }
        lookUpsWithValue(data,lookups,field,value)
    }
}
export {
    djangoLookups,
    lookUps,
    lookUpsWithValue,
    lookUpsFromArray
}