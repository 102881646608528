import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import employeService from "./employeServices";

export const createEmployee= createAsyncThunk(
    "employee/create-employee",
    async (employee, thunkAPI) => {
        try{
            return await employeService.createEmploye(employee)
        }
        catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getWorkingEmployees = createAsyncThunk(
  "employee/getWorkingEmployees",
  async (employee,thunkAPI)=>{
    try{
      return await employeService.getWorkingEmployee(employee)
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const getRetrieve = createAsyncThunk(
  "employee/retrieve",
  async (id,thunkAPI)=>{
    try{
      return await employeService.getRetrieve(id)
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
  }
)
export const getEmployeeStat= createAsyncThunk(
  "employee/getEmployeeStat",
  async (data,thunkAPI)=>{
    try{
      return await employeService.getEmployeeStat(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error); //
    }
  }
)

export const getOvertimeEmployee=createAsyncThunk(
  "employee/getOvertimeEmployee",

  async (data,thunkAPI)=>{
    try{
      return await employeService.getOvertimeEmployee(data)
    }catch(error){
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const resetState = createAction("Reset_all");


const initialState = {
    employees: [],
    stat:{
      latedays:0,
      missingdays:0,
      workhours:0,
      overtimepermonth:0,
    },
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
  };
  
  export const employeeSlice=createSlice({
    name:"employee",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
      .addCase(createEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdEmployee = action.payload;
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getWorkingEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkingEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.employees = action.payload;
      })
      .addCase(getWorkingEmployees.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getOvertimeEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOvertimeEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.employees = action.payload;
      })
      .addCase(getOvertimeEmployee.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getRetrieve.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRetrieve.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.employee = action.payload;
      })
      .addCase(getRetrieve.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getEmployeeStat.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployeeStat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.stat = action.payload;
      })
      .addCase(getEmployeeStat.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
    }
})


export default employeeSlice.reducer;