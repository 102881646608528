import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getRooms } from "../features/room/roomSlice"
import { RoomCard } from "../components/room/RoomCard"
import SmartPagination from "../components/handler/list/SmartPagination"
import { Input } from 'antd';
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Helmet } from "react-helmet-async"
const { Search } = Input;

export const ListRoom = () => {
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, SetcurrentPageSize] = useState(3)
    const [keywords, setKeywords] = useState(undefined)
    const roomstate = useSelector((state) => state.room)
    const loadDatas = (roomname = undefined, page = 1, page_size = 3) => {
        dispatch(getRooms({ roomname, page, page_size: page_size }))
    }
    useEffect(() => {
        loadDatas()
    }, [])
    const onSearch = async (value, _e, info) => {
        // console.log(info?.source, value);
        // await dispatch(validatesList(value));
        loadDatas(value)
        setKeywords(value)
    }
    return (
        <MainWaitComponent isLoading={roomstate.isLoading}>
            <MainWaitComponent.ErrorPage error={roomstate.isError} status={roomstate.errorstatus}>
                <Container>
                    <Helmet>
                        <title>Room list</title>
                    </Helmet>
                    <Row>
                        <h1>List of Rooms</h1>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="6" md="12" sm="12">
                            <Search placeholder="input search text" onSearch={onSearch} enterButton />
                        </Col>
                    </Row>
                    <Row >
                        {
                            roomstate.rooms.results?.map((room, index) => {
                                return (
                                    <Col key={index} lg="3" md="6" sm="12" className="m-3">
                                        <RoomCard room={room} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <Row>
                        <Col lg="4" md="12" sm="12"></Col>
                        <Col lg="4" md="12" sm="12">
                            <SmartPagination onPageChange={(page) => { loadDatas(keywords, page, pageSize); setCurrentPage(page) }} activePage={currentPage} totalPages={Math.round(roomstate.rooms.count / pageSize)} >
                            </SmartPagination>
                        </Col>
                        <Col lg="4" md="12" sm="12"></Col>

                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}