import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import workstationService from "./workstationServices";

export const createworkstation= createAsyncThunk(
    "workstations/create-workstation",
    async (workstation, thunkAPI) => {
        try{
            return await workstationService.createWorkstation(workstation)
        }
        catch (error) {
          console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getStatistic=createAsyncThunk(
  "workstations/get-statistic",
  async (data,thunkAPI)=>{
    try{
      return await workstationService.getStatistic(data)
    }catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const resetState = createAction("Reset_all");


const initialState = {
    workstations: [],
    statistics: {
      data:[],
      totalYear:0,
      stat:{
        present:0,
        missing:0,
        late:0
      }
    },
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
  };
  
  export const workstationSlice=createSlice({
    name:"workstations",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
      .addCase(createworkstation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createworkstation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdworkstation = action.payload;
      })
      .addCase(createworkstation.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getStatistic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStatistic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.statistics = action.payload;
      })
      .addCase(getStatistic.rejected, (state, action) => {
        state.errorstatus=state.errorstatus=action?.payload ? action.payload.response?.status:401;;
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
    }
})


export default workstationSlice.reducer;