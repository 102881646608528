import { Col, Row } from "react-bootstrap"

export const DescriptionComponent = (props)=>{
    const {label,value}= props
    return (
        <Row>
            <Col lg="6" md="12" sm="12" style={{fontSize:"2.4vh"}}><strong>{label}</strong>:</Col>
            <Col lg="6" md="12" sm="12" style={{fontSize:"2.4vh"}}>{value}</Col>
        </Row>
    )
}