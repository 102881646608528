import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import workstationReducer from "../features/workstation/workstationSlice";
import roomReducer from "../features/room/roomSlice";
import camReducer from "../features/cam/camSlice";
import employeeReducer from "../features/employe/employeSlice";
import presenceReducer from "../features/presence/presenceSlice";
export const store = configureStore({
    reducer: {
      auth: authReducer, // reducer qui est responsable de gerer la gestion d'acces dans toutes les pages
      workstation:workstationReducer, // reducer qui gere les postes disponibles dans la base
      room: roomReducer, // gerer l'acces aux API concernant la gestion des salles
      cam:camReducer, // gerer l'acces aux API concernant la gestion des cameras
      employee:employeeReducer, // gerer l'acces aux API concernant les employees
      presence:presenceReducer // gerer l'acces aux API concernant les presencees
    },
  });
  