import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Row } from "react-bootstrap";
import { change, fetchApiData } from "..";
import ScInput from "../inputs/ScComponent";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddButton } from "../../AddButton";
import { ErrorPage } from "../../error/ErrorPage";

const ReduxFormSave = (props) => {
  const {savemethod,loadfield,reducerkey }=props
  const [fields, setFields] = useState([]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    
    let get = async () => {
      try{
        let response=await loadfield()
      let temp = response.data;
    //   console.log(temp)
      for (let i of temp) {
              change(i);
            }
            setFields(temp);
      }catch(error){
        setError(error)
        // if (error.response?.status){
        //   return <ErrorPage status={error.response?.status}></ErrorPage>
        // }
        // window.location.href="/"
      }
    };
    get();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const alefa = new FormData(event.currentTarget);
    let data = Object.fromEntries(alefa.entries());

    await Promise.all(
      fields.map(async (field) => {
        const { fieldname } = field.fields;
        const fieldValue = alefa.get(fieldname);
        // console.log("field " + fieldname);

        if (fieldValue) {
          // Check if the field is a file input
          if (field.fields.type === "file") {
            const file = fieldValue;
            const base64 = await convertFileToBase64(file);
            //   console.log("ato " + alefa.get(fieldname) + " " + field.fields.type + base64);
            data[fieldname] = "data:image//jpeg;base64," + base64;
          } else {
            data[fieldname] = fieldValue;
          }
        }
      })
    );

    // console.log(`data: ${JSON.stringify(data)}`);
    await dispatch(savemethod(data));
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          const base64 = reader.result.toString().split(",")[1];
          resolve(base64);
        } else {
          reject("Error converting file to Base64");
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const newobject = useSelector((state) => state[reducerkey]);
  const {
    isSuccess,
    isError,
    isLoading,
  } = newobject;

  useEffect(() => {
    console.log(newobject);
    if (isSuccess && newobject[`created${reducerkey}`]){
      toast.success("Added Successfullly!");
    }
    // if (isSuccess && updatedBoutique) {
    //   toast.success("Category Updated Successfullly!");
    //   navigate("/admin/list-category");
    // }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading, newobject, reducerkey]);
  return (
    <div>
    {
      error?(
        <ErrorPage status={error.response?.status} action={()=> window.location.href="/"}></ErrorPage>
      ):(
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
      <ToastContainer />
      {fields.map((f, index) => (
        <Row key={index}>
          <ScInput {...f} />
        </Row>
      ))}
      <Row className="mt-1">
        <AddButton loading={isLoading} className={"btn btn-primary border-0 rounded-3 my-5"}>
              Add
          </AddButton>
      </Row>
    </Form>
      )
    }
    </div>
  );
};
export default ReduxFormSave;
