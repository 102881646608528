import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getnotvalidateadmin, validateadmin } from "../features/auth/authSlice"
import { Button, Table } from "antd"
import { Container, Row } from "react-bootstrap"
import { AdminTag } from "../components/admin/AdminTag"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import { MainWaitComponent } from "../components/wait/MainWaitComponent"

export const NotvalidateAdmin = () => {
    const dispatch = useDispatch()


    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: " Status",
            dataIndex: "status",
            key: "level",
            render: (_, { level }) => {
                return <AdminTag level={level} />;
            }
        }, {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, { id }) => {
                return <Button title="Accept" type="primary" onClick={() => accept(id)}>Accept</Button>
            }
        }

    ]
    useEffect(() => {
        dispatch(getnotvalidateadmin())
    }, [dispatch])

    const liste = useSelector((state) => state.auth.notvalidate)
    const newobject = useSelector((state) => state.auth);
    const {
        isSuccess,
        isError,
    } = newobject;
    useEffect(() => {
        console.log(newobject)
        if (isSuccess && newobject.createdUser) {
            toast.success("Accept Successfullly!");
        }
        if (isError) {
            toast.error("Something Went Wrong!");
        }
    }, [isSuccess, isError, newobject, newobject.createdUser])
    const accept = async (id) => {
        await dispatch(validateadmin({ id: id, level: 2 }));
        dispatch(getnotvalidateadmin())
    }
    const data = []
    for (let i = 0; i < liste.length; i++) {
        data.push({
            id: liste[i].id,
            key: liste[i].id,
            name: liste[i].name,
            email: liste[i].email,
            level: liste[i].level
        })
    }
    return (
        <MainWaitComponent isLoading={newobject.isLoading}>
            <MainWaitComponent.ErrorPage error={newobject.isError} status={newobject.errorstatus}>
                <Container>
                    <Row>
                        <h1>Request to become administrator</h1>
                    </Row>
                    <hr />
                    <Row>
                        <ToastContainer />
                        <Table columns={columns} dataSource={data} />
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )

}