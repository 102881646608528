import React, { useState } from "react";
import { IoIosNotifications } from "react-icons/io";
import { Modal, Button } from "react-bootstrap";

function NotificationComponent() {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <div className="position-relative">
            <IoIosNotifications className="fs-4" onClick={handleShow} />
            <span className="badge bg-warning rounded-circle p-1 position-absolute">
                3
            </span>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Contenu de votre modal */}
                    <p>Contenu de la notification...</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NotificationComponent;