import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPresence } from "../features/presence/presenceSlice"
import moment from "moment"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Accordion, Col, Container, Row } from "react-bootstrap"
import { Button, Card, Checkbox, Divider, Input, Space, Table } from "antd"
import { AdminTag } from "../components/admin/AdminTag"
import { AiOutlineFileSearch } from "react-icons/ai";
import { toast } from "react-toastify"
import { guardNow } from "../utils"
import CustomInput from "../components/CustomInput"
import { CSVLink } from "react-csv"
import { Helmet } from "react-helmet-async"
export const ListPresence = () => {
    const dispatch = useDispatch()
    const [searcheddate, setSearched] = useState(moment().format('YYYY-MM-DD'))
    const state = useSelector((state) => state.presence)
    const { presences, isLoading, isError } = state
    useEffect(() => {
        const total = checkedList.reduce((accumulator, currentObject) => accumulator + currentObject, 0)
        dispatch(getPresence({ date: searcheddate, criteria: total }))
    }, [])

    const columns = [
        {
            title: "Date",
            dataIndex: "established_at",
            key: "established_at",
            label: "established_at"
        },
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename",
            label: "completename",
            render: (text, record) => {
                const { completename, islate } = record
                return (
                    <p>{completename} <AdminTag.Presence presence={islate} /></p>
                )
            }
        },
        {
            title: "Job",
            dataIndex: "jobname",
            key: "jobname",
            label: "jobname"
        },
        {
            title: "Clock-In",
            dataIndex: "arrival",
            key: "arrival",
            label: "arrival"
        },
        {
            title: "Last Seen/Clock-Out",
            dataIndex: "departure",
            key: "departure",
            label: "departure"
        }, {
            title: "Hours Worked",
            dataIndex: "workinghour",
            key: "workinghour",
            label: "workinghour",
            render: (_, { workinghour, leftearly }) => {
                let textcolor = 'text-success'
                if (leftearly === 1) {
                    textcolor = 'text-danger'
                }
                if (workinghour < 1) {
                    return <p className="text-danger"><strong>Seen and left</strong></p>
                }
                return <p className={textcolor}><strong>{`${workinghour} ${'hour'}${workinghour > 1 ? 's' : ''}`}</strong></p>
            }
        }
    ]

    const handleDateChange = (e) => {
        // Get the selected date from the input
        const selectedDate = e.target.valueAsDate;

        // Format the date using moment
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');

        // Set the formatted date in the state
        setSearched(formattedDate);
    };



    const onSearch = () => {
        const total = checkedList.reduce((accumulator, currentObject) => accumulator + currentObject, 0)
        // console.log(total,searchdate)
        dispatch(getPresence({ date: searcheddate, criteria: total }))
    }

    useEffect(() => {
        if (isError) {
            toast.error("An error occured! Please refresh the page")
        }
    }, [isError])

    const [checkedList, setCheckedList] = useState([0]);
    const onChange = (list) => {
        setCheckedList(list);
    };


    // Transforming data and options
    const data = presences.map((item) => {
        return {
            completename: item.completename,
            jobname: item.jobname,
            established_at: item.established_at,
            arrival: item.arrival,
            departure: item.departure,
            islate: item.islate,
            workinghour: item.workinghour,
            leftearly: item.leftearly,
        };
    });

    const exportcolumn = [
        {
            key: "established_at",
            label: "established_at"
        },
        {
            key: "completename",
            label: "completename",
        }, {
            key: "jobname",
            label: "jobname"
        }, {
            key: "arrival",
            label: "arrival"
        }
        , {
            key: "departure",
            label: "departure"
        }, {
            key: "workinghour",
            label: "workinghour",
        }, {
            key: "islate",
            label: "islate"
        }, {
            key: "leftearly",
            label: "leftearly"
        }
    ]
    const act = [
        {
            label: 'Late',
            value: 1
        },
        {
            label: 'Left Early',
            value: 2
        }, {
            label: 'Late & Left Early',
            value: 3
        }, {
            label: 'Just Present',
            value: 0
        }
    ]
    const onCheckAllChange = (e) => {
        let liste = act.map((i) => i.value)
        setCheckedList(e.target.checked ? [1, 2, 3] : []);
    };
    const checkAll = act.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < act.length;

    return (
        <MainWaitComponent isLoading={isLoading}>
            <MainWaitComponent.ErrorPage error={isError} status={state.errorstatus}>
                <Container>
                    <Helmet>
                        <title>Attendance lists</title>

                    </Helmet>
                    <Row>
                        <h1>Attendance lists</h1>
                    </Row>
                    <hr />

                    <Row className="mb-3 mt-3">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Filter
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Card>
                                        <Row className="mb-2">
                                            <CustomInput
                                                type="date"
                                                label="Selecte a date"
                                                onChng={handleDateChange}
                                                val={searcheddate}
                                            />
                                        </Row>
                                        <Row>
                                            Other Caracteristics
                                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                                All
                                            </Checkbox>
                                            <Divider />
                                            <Checkbox.Group value={checkedList} options={act} defaultValue={[0]} onChange={onChange} />
                                        </Row>
                                        <Row>
                                            <Button type="primary" onClick={onSearch}>Apply</Button>
                                        </Row>
                                    </Card>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>

                    <Row className="mt-2">
                        <Table
                            title={() => {
                                return (
                                    <Row>
                                        <Col lg="10" md="6" sm="12">
                                            Here you can view  the workforce reports and export them for backup purpose or further analysis
                                        </Col>
                                        <Col lg="2" md="6" sm="12">

                                            <CSVLink className="btn btn-primary"
                                                target="_blank" filename={`attendance${moment(searcheddate).format("YYYYMMDD")}.csv`} data={presences} headers={exportcolumn}>
                                                EXPORT FILE
                                            </CSVLink>
                                        </Col>
                                    </Row>
                                )
                            }}
                            columns={columns} dataSource={data} pagination={{ pageSize: 10, }} />
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )

}