import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineBgColors,
  AiOutlineSetting,
  AiOutlinePullRequest,
  AiFillSecurityScan,
  AiFillBoxPlot,
  AiFillVideoCamera,
  AiOutlineUsergroupAdd,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { RiCouponLine } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ImBlog } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { FaClipboardList, FaBloggerB, FaEnvelope } from "react-icons/fa";
import { Badge, Button, Drawer, Layout, Menu, Space, notification, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import NotificationComponent from "./NotificationComponent";
import { getUnreadViolation } from "../features/presence/presenceSlice";
import { Single, getNotification } from "../utils";
import presenceService from "../features/presence/presenceServices";
const { Header, Sider, Content, Footer } = Layout;
const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const state = useSelector((state) => state.auth)
  const presence = useSelector((state) => state.presence)
  const dispatch = useDispatch()


  // notification
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    // Call the action when the content changes
    dispatch(getUnreadViolation());
  }, [location]);

  return (
    <Layout /* onContextMenu={(e) => e.preventDefault()} */>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <span className="sm-logo">GW</span>
            <span className="lg-logo">Guard Watch</span>
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key === "signout") {
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: "Dashboard",
            },
            {
              key: "sm",
              icon: <AiOutlineSetting className="fs-4" />,
              label: "System Management",
              children: [
                {
                  key: "validation",
                  icon: <AiOutlinePullRequest className="fs-4" />,
                  label: "Administrator Request",
                },
                {
                  key: "admin",
                  icon: <AiFillSecurityScan className="fs-4" />,
                  label: "System access",
                },
                {
                  key: "room",
                  icon: <AiFillBoxPlot className="fs-4" />,
                  label: "Room",
                },
                {
                  key: "room/list",
                  icon: <AiFillBoxPlot className="fs-4" />,
                  label: "Room List ",
                },
                {
                  key: "cam",
                  icon: <AiFillVideoCamera className="fs-4" />,
                  label: "Camera",
                },
                {
                  key: "cam/list",
                  icon: <AiFillVideoCamera className="fs-4" />,
                  label: "Camera List",
                },
                {
                  key: "workstation",
                  icon: <AiOutlineUsergroupAdd className="fs-4" />,
                  label: "Workstation",
                },
                {
                  key: "employee",
                  icon: <AiOutlineUserAdd className="fs-4" />,
                  label: "Employee",
                },
              ],
            },
            {
              key: "attendance/menu",
              icon: <FaClipboardList className="fs-4" />,
              label: "Attendance",
              children: [
                {
                  key: "attendance/list",
                  icon: <FaClipboardList className="fs-4" />,
                  label: "Attendance list",
                },
                {
                  key: "detection/history",
                  icon: <FaClipboardList className="fs-4" />,
                  label: "History",
                },
                {
                  key: "attendance",
                  icon: <FaClipboardList className="fs-4" />,
                  label: "Add detection"
                },
                {
                  key: "delays/list",
                  icon: <FaClipboardList className="fs-4" />,
                  label: "Missing list"
                }
              ]
            },
            {
              key: "employee/great",
              icon: <AiOutlineUserAdd className="fs-4" />,
              label: "Employee",
              children: [
                {
                  key: "employee/list",
                  icon: <AiOutlineUserAdd className="fs-4" />,
                  label: "Working Employee"
                },
                {
                  key: "employee/list/overtime",
                  icon: <AiOutlineUserAdd className="fs-4" />,
                  label: "Employee's overtime"
                },
              ]
            }
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <div className="d-flex gap-5 align-items-center">
            <div className=" m-2">
              <IoIosNotifications className="fs-4 " onClick={showDrawer} />
              <Badge count={presence.violations?.length}>
              </Badge>
              <Drawer title={`Notification ${presence.violations?.length}`} placement="right" onClose={onClose} open={open}>
                {
                  presence.violations && presence.violations.map((item) => <Single violation={item}></Single>)
                }
              </Drawer>
            </div>

            <div className="d-flex gap-3 align-items-center dropdown">
              <div>
                <img
                  width={32}
                  height={32}
                  src="https://stroyka-admin.html.themeforest.scompiler.ru/variants/ltr/images/customers/customer-4-64x64.jpg"
                  alt=""
                />
              </div>
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <h5 className="mb-0">Guard Watch</h5>
                <p className="mb-0">{(state.user) ? state.user.name : ""} </p>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                  >
                    View Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                    onClick={() => { dispatch(logout()); window.location.href = "/" }}
                  >
                    Signout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Orlando ©2023 Created by Ant UED
        </Footer>
      </Layout>

    </Layout>
  );
};
export default MainLayout;