import { Container, Row } from "react-bootstrap"
import * as yup from "yup";
import { useFormik } from "formik";
import { AddButton } from "../components/AddButton";
import { useEffect, useState } from "react";
import { PersonalInfo } from "../components/employee/PersonalInfo";
import employeService from "../features/employe/employeServices";
import { Space, Spin } from 'antd';
import { JobInfo } from "../components/employee/JobInfo";
import { Timetable } from "../components/employee/Timetable";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createEmployee } from "../features/employe/employeSlice";
import { MainWaitComponent } from "../components/wait/MainWaitComponent";
export const AddEmploye = () => {
    const [isloading, setLoading] = useState(false)
    const [rootLoader, setRootLoader] = useState(false)
    const [loadData, setLoadData] = useState()
    const [timetable, setTimetable] = useState([])
    const [error, setError] = useState(false)
    const [errorstatus, setErrorstatus] = useState(500)
    const dispatch = useDispatch()

    let schema = yup.object().shape({
        person: yup.object().shape({
            surname: yup.string().required("Surname is required"),
            firstname: yup.string().required("First name is required"),
            email: yup.string().required("Email is required").email("Email only"),
            idnumber: yup.string().required("ID number is required").matches(/^[0-9]+$/, "ID number must be a number"),
            address: yup.string().required("Address is required"),
            birthday: yup.date().required("Birthday is required"),
            image: yup.string().required("Image is required")
        }),
        job: yup.object().shape({
            workstationid: yup.string().required("Workstation is required"),
            hire_date: yup.date().required("Hire date is required"),
            hourperweek: yup.number().required("Hour per week is required"),
        })
    });


    const updateTimetable = (ind, data) => {
        const newtimetable = [...timetable];
        console.log(ind)
        const index = newtimetable.findIndex((entry) => entry.daytable === data.daytable || entry.daytable === ind + 1);

        if (index !== -1) {
            // Update the existing entry if found
            newtimetable[index] = { ...newtimetable[index], ...data };
        } else {
            // Push the new entry if not found
            newtimetable.push(data);
        }
        console.log(newtimetable)
        setTimetable(newtimetable);
    };

    const updateAll = (data) => {
        const newtimetable = [...timetable];
        for (let ind = 0; ind < 5; ind++) {
            const index = -1
            data.daytable = ind + 1
            if (index !== -1) {
                // Update the existing entry if found
                newtimetable[index] = { ...newtimetable[index], ...data };
            } else {
                // Push the new entry if not found
                newtimetable.push(data);
            }
        }
        console.log(newtimetable)
        setTimetable(newtimetable);
    }

    const deletetimetable = (index) => {
        const newtimetable = [...timetable]
        newtimetable.splice(index, 1);
        console.log(newtimetable)
        setTimetable(newtimetable)

    }

    const setHourPerWeek = (value) => {
        formik.setFieldValue("job.hourperweek", Math.round(value))
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            person: {
                surname: "",
                firstname: "",
                email: "",
                idnumber: "",
                address: "",
                birthday: "",
                image: ""
            },
            job: {
                workstationid: "",
                hire_date: "",
                hourperweek: 40,
            }
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            values["timetable"] = timetable
            console.log(JSON.stringify(values))
            try {
                await dispatch(createEmployee(values))
            } catch (e) {
                toast.error("Something Went Wrong!");
            }
        }
    })
    useEffect(() => {
        setRootLoader(true)
        const load = async () => {
            try {
                const data = await employeService.loadData()
                setLoadData(data)
            } catch (e) {
                console.log(e)
                setError(true)
                setErrorstatus(e?.response.status)

            }
        }
        load()
        setRootLoader(false)
    }, [])
    const newobject = useSelector((state) => state.employee);
    const {
        isSuccess,
        isError,
        isLoading,
    } = newobject;
    useEffect(() => {
        if (isSuccess && newobject.createdEmployee) {
            toast.success("Added Successfullly!");
        }
        if (isError) {
            toast.error("Something Went Wrong!");
        }
    }, [isError, isSuccess, newobject.createdEmployee])
    return (
        <MainWaitComponent isLoading={isLoading || rootLoader}>
            <MainWaitComponent.ErrorPage error={error} status={errorstatus}>
                <Container>
                    {
                        rootLoader ? (
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        ) : (
                            <>
                                <Row>
                                    <h1 className="text-center">Add employee</h1>
                                </Row>
                                <form action="" onSubmit={formik.handleSubmit}>
                                    <hr />
                                    <Row className="mb-2">
                                        <PersonalInfo formik={formik} />
                                    </Row>
                                    <hr />
                                    <Row>
                                        <JobInfo formik={formik} workstations={loadData?.workstation} />
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Timetable hourperweek={setHourPerWeek} changeAll={updateAll} exclude={deletetimetable} change={updateTimetable}></Timetable>
                                    </Row>
                                    <Row>
                                        <AddButton loading={isloading} className={"btn btn-success border-0 rounded-3 my-5"}>
                                            Add Employee
                                        </AddButton>
                                    </Row>
                                </form>
                            </>
                        )
                    }
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}