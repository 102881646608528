import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { Bar, Doughnut, Line } from "react-chartjs-2";

Chart.register(CategoryScale);

export const CustomChart = (props) => {
    return <></>
}

CustomChart.Line = ({ labels, datasets,options={},styling={} }) => {
    const chartData = {
        labels: labels,
        datasets: datasets
    };


    return <Line data={chartData} options={options} {...styling} />
}
CustomChart.Doughnut = ({ labels, datasets,options={},styling={}  }) => {
    const chartData = {
        labels: labels,
        datasets: datasets
    };


    return <Doughnut data={chartData} options={options} {...styling} />
}

CustomChart.Bar = ({ labels, datasets,options={},styling={}  }) => {
    const chartData = {
        labels: labels,
        datasets: datasets
    };


    return <Bar data={chartData} options={options} {...styling} />
}
CustomChart.Progress = ({ value, variant, description }) => {
    return (
        <Row>
            <Col lg="6" md="6" sm="6">{description}</Col>
            <Col lg="6" md="6" sm="6"><ProgressBar variant={variant} now={value} label={`${value} %`}></ProgressBar></Col>

        </Row>)
}