import { useDispatch, useSelector } from "react-redux"
import { getOvertimeEmployee } from "../features/employe/employeSlice"
import { Helmet } from "react-helmet-async"
import { Card, Select, Table } from "antd"
import { useNavigate } from "react-router"
import { useEffect, useState } from "react"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Accordion, Button, Col, Container, Row } from "react-bootstrap"
import { getValue } from "../utils"

import * as yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../components/CustomInput"
import { CSVLink } from "react-csv"
import moment from "moment"

export const ListOvertime = () => {
    const dispatch = useDispatch()
    const employee_state = useSelector((state) => state.employee)
    const [selectedmonth, setMonth] = useState(moment().month()+1)
    const [year, setYear] = useState(moment().year())
    const [from,setFrom] = useState(1)
    const [to,setTo] = useState()
    const navigate = useNavigate()

    // schema for search
    let schema = yup.object().shape({
        from: yup.number(),
        to: yup.number(),
        year: yup.number(),
    })

    useEffect(() => {
        dispatch(getOvertimeEmployee())
    }, [])

    const { employees } = employee_state

    const columns = [
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename"
        }, {
            title: "Overtime",
            dataIndex: "overtimepermonth",
            key: "overtimepermonth",
            render: (_, { overtimepermonth }) => {
                let d =0 
                if(overtimepermonth){
                    d=getValue(overtimepermonth, 2)
                }
                let colour = "info"
                if (overtimepermonth > 8) {
                    colour = "success"
                }
                return <p className={`text-${colour}`}>{d} hour(s)</p>
            }
        }
    ]

    // columns
    const csvcolumn = [
        {
            label: "Full Name",
            key: "completename",
        },
        {
            label: "Year",
            key: "year",
        },
        {
            label: " Month",
            key: "month",
        }, {
            label: "Overtime",
            key: "overtimepermonth",
        }
    ]
    const monthliste = moment.months()
    const options = monthliste.map((item, index) => {
        return {
            label: item,
            value: index + 1,
        };
    });

    const handleChange = (value) => {
        // setSelected(value)
        dispatch(getOvertimeEmployee({year,month:value,from,to}))
        // dispatch(getTop({ year: year, month: value, top: limit }))
        setMonth(value)
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            from: 1,
            to: 8,
            year:moment().year()
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            values["month"]=selectedmonth
            dispatch(getOvertimeEmployee(values))
            setYear(values["year"])
            setTo(values["to"])
            setFrom(values["from"])
        }
    })
    return (
        <MainWaitComponent isLoading={employee_state.isLoading}>
            <MainWaitComponent.ErrorPage error={employee_state.isError} status={employee_state.errorstatus}>
                <Helmet>
                    <title>Overtime of {monthliste[selectedmonth-1]},{year.toString()}</title>
                </Helmet>
                <Container>
                    <Row>
                        <h1>Over Time tracking</h1>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Search</Accordion.Header>
                                <Accordion.Body>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row>
                                            <Col lg="12" md="12" sm="12"
                                            >
                                                <CustomInput
                                                    type="number"
                                                    name="year"
                                                    label="Year"
                                                    onChng={formik.handleChange("year")}
                                                    onBlr={formik.handleBlur("year")}
                                                    val={formik.values.year}
                                                />
                                            </Col>
                                            <Col lg="6" md="12" sm="12">
                                                <CustomInput
                                                    type="number"
                                                    name="from"
                                                    label="Over time from"
                                                    onChng={formik.handleChange("from")}
                                                    onBlr={formik.handleBlur("from")}
                                                    val={formik.values.from}
                                                />
                                            </Col>
                                            <Col lg="6" md="12" sm="12">
                                                <CustomInput
                                                    type="number"
                                                    name="to"
                                                    label="To"
                                                    onChng={formik.handleChange("to")}
                                                    onBlr={formik.handleBlur("to")}
                                                    val={formik.values.to}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Button className="mt-2" variant="primary" type="submit">Search</Button>
                                        </Row>
                                    </form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row>
                        <Card border={false}>
                            <p>Overtime of {monthliste[selectedmonth-1]},{year}</p>
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Months"
                                onChange={handleChange}
                                options={options}
                            />
                            <Table
                            title={()=>{
                                return (
                                    <Row>
                                            <Col lg="10" md="6" sm="12">
                                                Here you can view  the workforce reports and export them for backup purpose or further analysis
                                            </Col>
                                            <Col lg="2" md="6" sm="12">

                                                <CSVLink className="btn btn-primary"
                                                    target="_blank" filename={`overtime${year}${monthliste[selectedmonth-1]}.csv`} data={employees} headers={csvcolumn}>
                                                    EXPORT FILE
                                                </CSVLink>
                                            </Col>
                                        </Row>
                                )
                            }}
                            columns={columns} dataSource={employees}></Table>
                        </Card>
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}