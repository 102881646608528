import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getEmployeeStat, getRetrieve } from "../features/employe/employeSlice"
import { MainWaitComponent } from "../components/wait/MainWaitComponent"
import { Accordion, Col, Container, Row,Button as Btn } from "react-bootstrap"
import { Avatar, Button, Card, Select } from "antd"
import { DescriptionComponent } from "../components/DescriptionComponent"
import { formatString } from "../utils"
import { StatisticBoard } from "../components/presence/StatisticBoard"
import { Helmet } from "react-helmet-async"
import CustomInput from "../components/CustomInput"

export const EmployeeDetails = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const employee_state = useSelector((state) => state.employee)
    const [selectedmonth, setMonth] = useState(moment().month() + 1)
    const [year, setYear] = useState(moment().year())


    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getRetrieve(id))
        dispatch(getEmployeeStat({ year: moment().year(), month: moment().month() + 1, id }))
    }, [id])

    const monthliste = moment.months()
    const options = monthliste.map((item, index) => {
        return {
            label: item,
            value: index + 1,
        };
    });

    const handleChange = (value) => {
        // setSelected(value)
        // dispatch(getEmployeeStat({ year, month: value, id }))
        // dispatch(getTop({ year: year, month: value, top: limit }))
        setMonth(value)
    };
    const changeYear = (e) => {
        const value = e.target.value;
        setYear(value)
    }
    const search =()=>{
        dispatch(getEmployeeStat({ year, month: selectedmonth, id }))

    }
    const { employee } = employee_state
    const { latedays, missingdays, workhours, overtimepermonth } = employee_state.stat
    return (
        <MainWaitComponent isLoading={employee_state.isLoading}>
            <MainWaitComponent.ErrorPage error={employee_state.isError} status={employee_state.errorstatus}>
                <Container>
                    <Helmet>
                        <title>Profile of {employee?employee?.firstname:"Loading..."} in {monthliste[selectedmonth - 1]},{year.toString()}</title>
                    </Helmet>
                    <Row className="mb-3">
                        <Col>
                            <Button onClick={() => navigate(-1)}>Back</Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Search</Accordion.Header>
                                <Accordion.Body>
                                    <form onSubmit={search}>
                                        <Row>
                                            <Col lg="12" md="12" sm="12" className="mb-2"
                                            >
                                                <CustomInput
                                                    type="number"
                                                    name="year"
                                                    label="Year"
                                                    onChng={changeYear}
                                                    val={year}
                                                />
                                            </Col>
                                            <Col lg="12" md="12" sm="12">
                                                Month
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Months"
                                                    onChange={handleChange}
                                                    options={options}
                                                />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Btn className="mt-2" variant="primary" type="submit">Search</Btn>
                                        </Row>
                                    </form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row>
                        <Col lg="7" md="12" sm="12" className="mb-3">
                            <Card bordered={false} style={{ minWidth: "25em", height: "20.4em" }}>
                                <Row>
                                    <Col lg="12" md="12" sm="12">
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <span>
                                                    <Avatar
                                                        size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                                        src={employee?.image}
                                                    >{employee?.firstname}
                                                    </Avatar>
                                                </span>
                                            </Col>
                                            <Col lg="12" md="12" sm="12">
                                                <h2>{`${employee?.firstname} ${employee?.surname}`}</h2>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <DescriptionComponent label={"ID Number"} value={formatString(employee?.idnumber)} />
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <DescriptionComponent label={"Birthday"} value={employee?.birthday} />
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <DescriptionComponent label={"Contact"} value={employee?.email} />
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <DescriptionComponent label={"Address"} value={employee?.address} />
                                    </Col>

                                </Row>
                            </Card>
                        </Col>
                        <Col lg="5" md="12" sm="12">
                            <Row>
                                <Col lg="6" md="12" sm="12" className="mb-4">
                                    <StatisticBoard.Delay value={latedays} />
                                </Col>
                                <Col lg="6" md="12" sm="12" className="mb-4">
                                    <StatisticBoard.Missing value={missingdays} />
                                </Col>
                                <Col lg="6" md="12" sm="12" className="mb-5">
                                    <StatisticBoard.Custom style={{ color: '#1a9609' }} title={"Working Hour"} value={workhours} />
                                </Col>
                                <Col lg="6" md="12" sm="12" className="mb-5">
                                    <StatisticBoard.Custom style={{ color: '#1a9609' }} title={"Overtime Hour"} value={overtimepermonth} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </MainWaitComponent.ErrorPage>
        </MainWaitComponent>
    )
}