import { Accordion, Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { StatisticBoard } from "../components/presence/StatisticBoard"
import * as yup from "yup";
import { useFormik } from "formik";
import CustomInput from "../components/CustomInput";
import { CustomChart } from "../components/chart/Chart";
import { Card, Segmented, Select, Switch, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { dataToDataset, getRandomColor, getValue, guardNow, month } from "../utils";
import workstationService, { merge } from "../features/workstation/workstationServices";
import { useEffect, useState } from "react";
import { getStatistic } from "../features/workstation/workstationSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { ProgressChart } from "../components/chart/Customchart";
import { AdminTag } from "../components/admin/AdminTag";
import { getPresence, getTop } from "../features/presence/presenceSlice";
import { Helmet } from "react-helmet-async";
export const DashBoard = () => {

    //states 
    const workstate = useSelector((state) => state.workstation)
    const presencestate = useSelector((state) => state.presence)

    const { statistics, isError } = workstate
    const { tops } = presencestate
    const [year, setYear] = useState(moment().year())
    const [dateforMonth, setMonth] = useState(moment().format("MM"))
    const [dateForNumber, setDateForNumber] = useState(guardNow())
    const [value, setValue] = useState('Today');
    const [limit, setLimit] = useState(3)
    const [tostat, settoState] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getStatistic({ year: moment().year(), delays: tostat, date: guardNow() }));
        dispatch(getTop({ year: moment().year(), month: moment().format("MM"), top: limit }))
        dispatch(getPresence({date:guardNow(),criteria:0}))
        return () => {
            console.log("Unmount")
            // Cleanup logic here if needed
        };
    }, [])
    // css variable
    const board = 12

    // schema for the format
    let schema = yup.object().shape({
        year: yup.string(),
        date: yup.string(),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            year: moment().year(),
            date: guardNow()
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            dispatch(getStatistic({ year: values["year"], delays: tostat, date: values["date"] }))
            dispatch(getPresence({date:values["date"],criteria:0}))
            setYear(values["year"])
            setDateForNumber(values["date"])
            setValue('Searched')
        }
    })

    const onChangeSwitch = (checked) => {
        console.log(`switch to ${checked}`);
        dispatch(getStatistic({ year, delays: checked, date: dateForNumber }))
        settoState(checked)
    };

    const handleChange = (value) => {
        // setSelected(value)
        dispatch(getTop({ year: year, month: value, top: limit }))
        setMonth(value)
    };

    const getLabel = () => {
        if (tostat) {
            return "Delay"
        } else {
            return "Missing"
        }
    }

    const monthliste = moment.months()
    const options = monthliste.map((item, index) => {
        return {
            label: item,
            value: index + 1,
        };
    });
    const data = {
        labels: month(),
        datasets: statistics.data.map((data) => {
            const l = workstationService.transformTo12(data)
            return dataToDataset(l, (d) => d[0]["jobname"], (d) => d["result"], { fill: true, tension: 0.5 })
        })
    }

    const colorArray = ['rgba(25, 113, 255,0.8)', 'rgba(242, 24, 31,0.8)', '#5733FF', '#FF5733', '#33FF57', '#5733FF'];
    const doughnutdata = {
        datasets: [{
            label:"Pourcentage delay by year",
            data: statistics.data.map((data) => {
                const value = statistics.totalYear
                let sum = data.reduce((accumulator, currentObject) => accumulator + currentObject.result, 0);
                return getValue((sum / value) * 100, 0)
            }),
            backgroundColor: colorArray
        }
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: statistics.data.map((data) => {
            return data[0]['jobname']
        })
    };

    // column per department
    const columns = [
        {
            title: "Job",
            dataIndex: "jobname",
            key: "jobname"
        },
        {
            title: "Month",
            dataIndex: "month",
            key: "month",
            render: (_, record) => {
                return <>{month()[record.month - 1]}</>;
            },
        },

        {
            title: "Count",
            dataIndex: "result",
            key: "result",
            fixed: 'right',
            width: 130,
        }
    ]
    // data per department
    const tabledata = merge(statistics.data)


    const topcolumns = [
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename",
        },
        {
            title: "Arrival Time",
            dataIndex: "earlyarrival",
            key: "earlyarrival"
        }, {
            title: "Pos",
            dataIndex: "key",
            key: "key",
            render: (_, { key }) => {
                return <AdminTag.Cup pos={key} />
            }
        }
    ]

    const topdata = []
    for (let i = 0; i < tops.length; i++) {
        topdata.push({
            key: i + 1,
            completename: tops[i].completename,
            earlyarrival: tops[i].earlyarrival,
        })
    }

    const presencecolumns = [
        {
            title: "Full Name",
            dataIndex: "completename",
            key: "completename",
            render: (text, record) => {
                const { completename, islate } = record
                return (
                    <p>{completename} <AdminTag.Presence presence={islate} /></p>
                )
            }
        },
        {
            title: "Arrival",
            dataIndex: "arrival",
            key: "arrival"
        },
    ]
    return (
        <Container>
            <Helmet>
                <title>DashBoard of {getLabel()} {year.toString()}</title>
            </Helmet>
            <Row>
                <h1>DashBoard</h1>
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        <>DashBoard  <Switch loading={workstate.isLoading} onChange={onChangeSwitch} checkedChildren="Delays" unCheckedChildren="Missing" defaultChecked /></>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row className="mb-3">
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Advanced Search</Accordion.Header>
                        <Accordion.Body>
                            <form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col lg="12" md="12" sm="12">
                                        <p>{"Delays"}</p>
                                        <hr />
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <CustomInput
                                            type="number"
                                            name="year"
                                            label="Year"
                                            onChng={formik.handleChange("year")}
                                            onBlr={formik.handleBlur("year")}
                                            val={formik.values.year}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12" md="12" sm="12">
                                        <CustomInput
                                            type="date"
                                            name="date"
                                            label="Search for a date"
                                            onChng={formik.handleChange("date")}
                                            onBlr={formik.handleBlur("date")}
                                            val={formik.values.date}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Button className="mt-2" variant="primary" type="submit">Search</Button>
                                </Row>
                            </form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
            <Row>
                <Col lg="6" md="12" sm="12">
                    <Row>
                        <Card bordered={false}>
                            <CustomChart.Line labels={data.labels} datasets={data.datasets}
                                options={{
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: `${getLabel()} Per Department in ${year}`
                                        }
                                    }
                                }}></CustomChart.Line>
                        </Card>
                    </Row>
                    <Row className="mt-3">
                        <Card bordered={false}>
                            {
                                <ProgressChart data={statistics.data} title={`Pourcentage of ${getLabel()} By Department`} value={(data) => {
                                    const value = statistics.totalYear
                                    let sum = data.reduce((accumulator, currentObject) => accumulator + currentObject.result, 0);
                                    return getValue((sum / value) * 100, 0)

                                }}
                                    description={(data) => {
                                        return data[0].jobname
                                    }}
                                />
                            }
                        </Card>
                    </Row>
                    <Row className="mt-3 p-0">
                        <Col lg="6" md="12" sm="12">
                            <Card bordered={false} size="small">
                                <CustomChart.Doughnut
                                    // styling={{ width: "100%", height: "100%" }}  // Adjusted width and height styles
                                    options={{ responsive: true,
                                     }}
                                    labels={doughnutdata.labels}
                                    datasets={doughnutdata.datasets}
                                ></CustomChart.Doughnut>
                            </Card>
                        </Col>

                        <Col lg="6" md="12" sm="12" className="mt-2">
                            <Card bordered={false}>
                                <p className="text-center">{getLabel()} List ,{year}</p>
                                <Table columns={columns} dataSource={tabledata} scroll={{
                                    x: 340,
                                    y: 120,
                                }}></Table>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg="6" md="12" sm="12">
                    <Row>
                        <Col lg="6" md="6" sm="12">
                            <Row>
                                <Segmented options={['Today', 'Searched']} value={value} onChange={(e) => {
                                    if (e == 'Today') {
                                        dispatch(getStatistic({ year, delays: tostat, date: guardNow() }))
                                        dispatch(getPresence(guardNow()))
                                    } else {
                                        dispatch(getStatistic({ year, delays: tostat, date: dateForNumber }))
                                        dispatch(getPresence(dateForNumber))
                                    }
                                    setValue(e)
                                }} />
                            </Row>
                            <Row className="mt-3">
                                <Col lg={board} md={board} sm={board} className="mb-3">
                                    <StatisticBoard.Presence value={statistics.stat.present} />
                                </Col>
                                <Col lg={board} md={board} sm={board} className="mb-3">
                                    <StatisticBoard.Delay value={statistics.stat.late} />
                                </Col>
                                <Col lg={board} md={board} sm={board} className="mb-3">
                                    <StatisticBoard.Missing value={statistics.stat.missing} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <Row>
                                <Col lg="12" md="12" sm="12" className="mb-2 mt-2">
                                    <Card bordered={false} className="p-0">
                                        <p>Late Day</p>
                                        <p className="text-left text-success" style={{ fontSize: "1.2em" }}>{statistics.stat?.weekday? moment().day(statistics.stat?.weekday).format("dddd"):''}</p>
                                    </Card>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                    <Card bordered={false}>
                                        <h4 className="text-center">Attendance</h4>
                                        <Table columns={presencecolumns} dataSource={presencestate.presences}
                                            scroll={{
                                                y: 135,
                                            }}
                                        ></Table>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="12" md="12" sm="12" className="mt-3">
                            <Card bordered={false}>
                                <p className="text-center">Top {limit} earliest of {monthliste[dateforMonth - 1]},{year}</p>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Months"
                                    onChange={handleChange}
                                    options={options}
                                />
                                <Table columns={topcolumns} dataSource={topdata}></Table>
                            </Card>
                        </Col>
                    </Row>
                    {/* eto */}
                    <Row className="mt-3">

                    </Row>
                </Col>
            </Row>
        </Container>
    )
}