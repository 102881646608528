import { Row, Col } from "react-bootstrap"
import { useState } from "react"
import CustomInput from "../CustomInput"

export const Timetable = (props) => {
    const { change,changeAll, exclude,hourperweek } = props
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // Initialize state to keep track of checked checkboxes
    const [checkedDays, setCheckedDays] = useState(Array(7).fill(false));
    const [hour_per_week, setHourPerWeek] = useState(0)
    const [arrivalTimes, setArrivalTimes] = useState(Array(7).fill(""));
    const [departureTimes, setDepartureTimes] = useState(Array(7).fill(""));
    const [all, setall] = useState(false)
    const handleCheckboxChange = (index) => {
        // Copy the current state array
        const newCheckedDays = [...checkedDays];
         // Toggle the value at the given index
         newCheckedDays[index] = !newCheckedDays[index];

        if (!newCheckedDays[index]) {
            const newArrivalTimes = [...arrivalTimes];
            newArrivalTimes[index] = "";
            setArrivalTimes(newArrivalTimes);
            const newDepartureTimes = [...departureTimes];
            newDepartureTimes[index] = "";
            setDepartureTimes(newDepartureTimes);
            exclude(index)
            calculateHourPerWeek(newArrivalTimes, newDepartureTimes)
        } else {
            change(index, { daytable: index + 1 })
        }
        setCheckedDays(newCheckedDays);

    };
    const handleCheckboxChange2 = () => {
        // Copy the current state array
        const newCheckedDays = [...checkedDays];
        changeAll()
        for(let index=0;index<5;index++){
            newCheckedDays[index] = !all;
        }
        setCheckedDays(newCheckedDays);
        setall(!all)


    };

    const handleTimeChange = (index, type, time) => {
        let a = arrivalTimes
        let b = departureTimes
        if (type === "arrival") {
            const newArrivalTimes = [...arrivalTimes];
            newArrivalTimes[index] = time;
            setArrivalTimes(newArrivalTimes);
            a = newArrivalTimes
            change(index, { arrival: time })
        } else if (type === "departure") {
            const newDepartureTimes = [...departureTimes];
            newDepartureTimes[index] = time;
            setDepartureTimes(newDepartureTimes);
            change(index, { departure: time })
            b = newDepartureTimes
        }
        calculateHourPerWeek(a, b)
    };

    // Calculate the total hours per week
    const calculateHourPerWeek = (a, d) => {
        let totalHours = 0;
        for (let i = 0; i < 7; i++) {
            if (checkedDays[i] && a[i] && d[i]) {
                const arrivalTime = new Date(`2000-01-01T${a[i]}`);
                const departureTime = new Date(`2000-01-01T${d[i]}`);
                const hoursDifference = (departureTime - arrivalTime) / 3600000; // 1 hour = 3600000 milliseconds
                totalHours += hoursDifference;
            }
        }
        hourperweek(totalHours)
        setHourPerWeek(totalHours);
    };

    return (
        <>
            <h3>Time Table</h3>
            <Row>
                <Col lg="12" md="12" sm="12">
                    <p>Hour per week: {hour_per_week} Hour{hour_per_week > 1 ? "s" : ""}</p>
                </Col>
            </Row>
            <Row>
                {
                    days.map((day, index) => {
                        const dis = index > 4;
                        return (
                            <>
                                <div>
                                    <Row>
                                        <Col lg="12" md="12" sm="12">
                                            <input type="checkbox"
                                                key={index}
                                                value={index + 1}
                                                checked={checkedDays[index]}
                                                onChange={() => handleCheckboxChange(index)}
                                                disabled={dis}
                                            />
                                            <label>{day}</label>
                                        </Col>
                                        <Col lg="12" md="12" sm="12">
                                            {checkedDays[index] &&

                                                <>
                                                    Time for {day}
                                                    <Row>
                                                        <Col lg="6" md="6" sm="12">
                                                            <CustomInput
                                                                type="time"
                                                                name="arrival"
                                                                label="Time to arrive"
                                                                onChng={(e) => handleTimeChange(index, "arrival", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col lg="6" md="6" sm="12">
                                                            <CustomInput
                                                                type="time"
                                                                name="departure"
                                                                label="Time to leave"
                                                                onChng={(e) => handleTimeChange(index, "departure", e.target.value)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>

                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )
                    })
                }
            </Row>
        </>
    )
}