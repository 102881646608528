import { Col, Row } from "react-bootstrap"
import { CustomChart } from "./Chart"

export const ProgressChart = (props)=>{
    const {data,title,value,description}=props
    return (
        <Row>
            <Col lg="12" md="12" sm="12">
                <p className="text-center"><strong>{title}</strong></p>
            </Col>
            <Col lg="12" md="12" sm="12">
                {
                    data?.map((d,index)=>{
                        let defval=value(d)
                        let variant="danger"
                        if(defval<70){
                            variant="info"
                        }
                        if(defval<50){
                            variant="warning"
                        }
                        if(defval<15){
                            variant="success"
                        }
                        return (
                            <CustomChart.Progress key={index} description={(description(d))} value={defval} variant={variant}/>
                        )
                    })
                }
            </Col>
        </Row>
    )
}