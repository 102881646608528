import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap"
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/auth/authSlice";
import styles from "../styles/css/login/index.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { checkCaptcha } from "../utils";
let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const recaptcha = useRef();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setLoading(true)
      const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {

      alert("Please verify the reCAPTCHA!");
      setLoading(false)

    } else {

      // make form submission
      const result=await checkCaptcha(captchaValue)
      if(result){
        dispatch(login(values));
      }
      setLoading(false)

    }
    },
  });
  const authState = useSelector((state) => state);

  const { user, isError, isSuccess, isLoading, message } = authState.auth;

  useEffect(() => {
    if (isSuccess) {
      // navigate("admin");
      setLoading(false)
      window.location.href = "/watch";
    } else {
      setLoading(false)
      navigate("");
    }
  }, [user, isError, isSuccess, isLoading, navigate]);
  return (
    <div className="container-fluid">
      <Helmet>
          <title>Guard watch Sign </title>
      </Helmet>
      <div className={styles.center}>
        <div className={styles.item}>
          <div className=" bg-white rounded-3 mx-auto mt-4">
            <h3 className="text-center title">Guard Watch</h3>
            <p className="text-center">Login to your account to continue.</p>
            <div className="error text-center">
              {message.message === "Rejected" ? "You are not an Admin" : ""}
            </div>
            <form action="" onSubmit={formik.handleSubmit}>
              <CustomInput
                type="text"
                label="Email Address"
                id="email"
                name="email"
                onChng={formik.handleChange("email")}
                onBlr={formik.handleBlur("email")}
                val={formik.values.email}
              />
              <div className="error mt-2">
                {formik.touched.email && formik.errors.email}
              </div>
              <CustomInput
                type="password"
                label="Password"
                id="pass"
                name="password"
                onChng={formik.handleChange("password")}
                onBlr={formik.handleBlur("password")}
                val={formik.values.password}
              />
              <div className="error mt-2">
                {formik.touched.password && formik.errors.password}
              </div>
              <div className="mb-3 text-end">
                <Link to="register" className="">
                  Register
                </Link>
              </div>
              
              <button
                className="border-0 px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5"
                style={{ background: "rgb(0,120,255)" }}
                type="submit"
              >
                {
                  loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : "Sign In"
                }
              </button>
              <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
